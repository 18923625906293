import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getBasket } from "../modules/basket";
import { basketType } from "src/types";

export const useBasket = () => {
    const basket: basketType[] = useSelector(getBasket);

    const totalBasket = useMemo(() => {
        return basket.reduce((acc, el) => {
            if (el.productSellingPrice && el.productSellingPrice.length > 0) {
                const lastPriceEntry = el.productSellingPrice[el.productSellingPrice.length - 1];
                const price = lastPriceEntry?.price;

                if (typeof price === 'number') {
                    let itemTotal = price * el.quantitySelected;

                    if (el.extraSideDish && el.extraSideDish.length > 0) {
                        const extraSideDishTotal = el.extraSideDish.reduce((sideAcc, sideDish) => {
                            const sideDishPrice = sideDish.price;
                            return sideAcc + (typeof sideDishPrice === 'number' ? sideDishPrice : 0);
                        }, 0);

                        itemTotal += extraSideDishTotal * el.quantitySelected;
                    }

                    return acc + itemTotal;
                }
            }

            return acc;
        }, 0);
    }, [basket]);


    return { totalBasket }
}