import React from "react";
import { useController } from "./useController";

import { LivraisonComponent } from "./Livraison.component";

export const Livraison: React.FC = () => {

  const {
    clientData,
    onSetClientDataLivraison
  } = useController();

  return (
    <LivraisonComponent
      clientData={clientData}
      onSetClientDataLivraison={onSetClientDataLivraison}
    />
  );
};
