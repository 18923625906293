import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    RESET_SALE_STATE,
    SET_NEW_TRANSACTION_REQUEST,
    SET_PAYMENT_TYPE_REQUEST,
} from "../../modules";
import { LOCAL_STORAGE, Sale, basketType, paymetTypeState } from "../../types";
import { useNavigation, useSessionData } from "@hooks";
import { enumNavigationPath } from "@enums";

export const useController = () => {

    const dispatch = useDispatch();
    const { navigateTo } = useNavigation();
    const { sessionData } = useSessionData();

    const paymentTypeSave = useSelector(
        (state: any) => state.PaymentTypeReducers.payment
    );

    const addNewTransactionSuccess = useSelector(
        (state: any) => state.SalesReducers.addNewTransactionSuccess
    );

    const addNewTransactionErrorState = useSelector(
        (state: any) => state.SalesReducers.addNewTransactionErrorState
    );

    console.log('==addNewTransactionSuccess==>', addNewTransactionSuccess);

    const basket: basketType[] = useSelector(
        (state: any) => state.BasketReducers.basket
    );

    const deliveryData = useSelector(
        (state: any) => state.LivraisonReducers.clientData
    );

    const [localNewSale, setLocalNewSale] = useState<Sale>();
    // console.log('addNewTransactionErrorState',addNewTransactionErrorState)

    useEffect(() => {
        if (addNewTransactionSuccess) {
            onResetSaleState();
            window.localStorage.setItem(
                LOCAL_STORAGE.SALE_KEY,
                JSON.stringify(localNewSale)
            );
            navigateTo(enumNavigationPath.success);
        }
        if (addNewTransactionErrorState) {
            alert("Impossible de valider l'opération, veuillez réessayer plus tard");
            window.localStorage.removeItem(LOCAL_STORAGE.SALE_KEY);
        }
    }, [addNewTransactionSuccess, addNewTransactionErrorState, navigateTo, localNewSale]);

    const onAddNewSale = useCallback((newSale: Sale) => {
        setLocalNewSale(newSale);
        return dispatch({
            type: SET_NEW_TRANSACTION_REQUEST,
            payload: newSale,
        });
    }, [dispatch]);

    const onResetSaleState = useCallback(() => {
        return dispatch({ type: RESET_SALE_STATE });
    }, [dispatch]);

    const onSelectedPayment = useCallback(
        (payment: paymetTypeState) => {
            return dispatch({ type: SET_PAYMENT_TYPE_REQUEST, payload: payment });
        },
        [dispatch]
    );

    return {
        sessionData,
        paymentTypeSave,
        onSelectedPayment,
        onAddNewSale,
        basket,
        deliveryData,
    }
}
