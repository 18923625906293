import { actionsLivraison, livraisonInitialState } from "../../types";
import * as actions from "./actions";

const initialState: livraisonInitialState = {
  clientData: null,
};

export default function LivraisonReducers(
  state: livraisonInitialState = initialState,
  { type, payload }: actionsLivraison
) {
  switch (type) {
    case actions.SET_LIVRAISON_CLIENT_INFOS_REQUEST:
      return {
        ...state,
      };
    case actions.SET_LIVRAISON_CLIENT_INFOS_SUCCESS:
      return {
        ...state,
        clientData: payload,
      };
    case actions.SET_LIVRAISON_CLIENT_INFOS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
