import React, { useMemo } from "react";
import { BButtonProps } from "./interface";
import { Button as BButton } from 'primereact/button';
import './styles.css';

export const Button: React.FC<BButtonProps> = ({
    label,
    onButtonPress,
    customClassname,
    buttonIconName,
    template = 'A'
}) => {
    const cn_bmButton = useMemo(() => (
        `bm-button ${customClassname} bm-button-${template}`
    ), [customClassname, template]);

    return (
        <BButton
            icon={buttonIconName}
            iconPos="right"
            label={label}
            onClick={onButtonPress}
            className={cn_bmButton}
            // unstyled
            style={{
                minWidth: '6rem',
                fontSize: '16px'
            }}
        />
    )
}