import React, { useState } from "react";

import { LoginProps } from './interfaces';

import styles from './styles.module.css';
import { useController } from "./useController";

export const LoginComponent: React.FC<LoginProps> = ({

}) => {

    const { onGoogleLoginPress, onLoginWithPhoneNumber, userData } = useController()

    return (
        <div className={styles.container}>

            <h1 onClick={onLoginWithPhoneNumber}>
                LoginComponent
            </h1>
            <div id="recaptcha-container"></div>
        </div>
    )
}
