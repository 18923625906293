import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { enumNavigationPath } from '@enums';

const ProtectedRoute: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to={enumNavigationPath.onboarding} />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
