import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HomeProps } from "./interfaces";
import { enumNavigationPath } from "@enums";
import { useScreenSize, useSessionData } from "@hooks";
import { BannerSlider, ProductCard } from "@byme-ui-components/templates";
import { BannerDataType } from "@byme-ui-components/templates/BannerSlider/interfaces";
import { Header } from "@byme-ui-components/organismes";
import { formatNumberToPrice, formatTextMaxChars_15, formatTextMaxChars_50 } from "@functions";
import styles from "./styles.module.css";
import { Product, productsByCategories } from "../../types";
import { Paragraph, Title } from "@byme-ui-components/atomes";
import { useNavigate } from "react-router-dom";
import { ContentComponent } from "./ContentComponent";

export const HomeComponent: React.FC<HomeProps> = ({
  carrouselData,
  productByCategories,
  navigateTo,
  onSelectedProduct,
  onSelectedCategory,
  onAddProductInBasket,
  onSelectedProductQuantityDown,
  basket
}) => {

  const { isDesktop, isMobile } = useScreenSize();
  const goTo = useNavigate()
  const { sessionData, organisationTemplate } = useSessionData();
  const organisationLogo = useMemo(() => {
    return sessionData?.logos?.logoMediumImage;
  }, [sessionData]);

  const [search, setSearch] = useState<string>('');
  const [allCategories, setAllCategories] = useState<productsByCategories[]>([]);

  const sliceArrBy = useMemo(() => {
    if (isDesktop || isMobile) return -4;
    return -5
  }, [isDesktop, isMobile])

  const handleGoToBasket = useCallback(() => {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const adaptedCarouselData = useMemo(() => {
    return carrouselData.reduce((acc, currentData) => {
      const bannerData: BannerDataType = {
        title: "",
        subTitle: "",
        buttonName: "",
        bgImgUri: currentData.uri
      };
      acc.push(bannerData);

      return acc;
    }, [] as BannerDataType[]);
  }, [carrouselData]);

  const handleCardPress = useCallback((product: Product, category: productsByCategories) => {
    onSelectedProduct(product);
    onSelectedCategory(category);
    navigateTo(enumNavigationPath.product);
  }, [onSelectedProduct, onSelectedCategory]);

  useEffect(() => {
    if (search) {
      // Recherche par nom de catégorie
      const categoryNameResult = productByCategories.filter(category =>
        category.categoryName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      // Recherche par nom de produit
      const productNameResult = productByCategories.reduce((acc, category) => {
        const filteredProducts = category.produits.filter(product =>
          product.productName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );

        if (filteredProducts.length > 0) {
          acc.push({ ...category, produits: filteredProducts });
        }

        return acc;
      }, [] as productsByCategories[]);

      // Combiner les résultats
      const combinedResults = [
        ...categoryNameResult,
        ...productNameResult.filter(
          productCategory => !categoryNameResult.find(category => category._id === productCategory._id)
        )
      ];

      setAllCategories(combinedResults);
    } else {
      setAllCategories(productByCategories);
    }
  }, [search, productByCategories]);

  const renderContent = useCallback(() => {
    return (
      <div className={styles.productsContainer}>
        {allCategories.map((category, i) => {
          return (
            <div key={i} className={styles.categoryContainer}>
              <div className={styles.categoryHeaderRow}>
                <Title
                  label={category.categoryName}
                  variant='h_xl'
                  weight='bold'
                  className={styles.categoryNameStyle}
                  template={organisationTemplate}
                />
                <Paragraph
                  variant={isDesktop ? 'p_xl' : 'p_md'}
                  label='voir plus'
                  weight='bold'
                  onParagraphClik={() => {
                    onSelectedCategory(category);
                    navigateTo(enumNavigationPath.carte);
                  }}
                  template={organisationTemplate}
                />
              </div>
              <ContentComponent isDesktop={isDesktop}>
                {category.produits.slice(sliceArrBy).map((product, j) => {
                  const price = product.productSellingPrice![product.productSellingPrice?.length! - 1].price;
                  const elementEx = basket.find(ele => ele._id === product._id);
                  const quantity = elementEx && elementEx.quantitySelected;

                  return (
                    <ProductCard
                      key={j}
                      name={formatTextMaxChars_15(product.productName)}
                      description={formatTextMaxChars_50(product.productDescription)}
                      amount={formatNumberToPrice(price)}
                      currency={"Fcfa"}
                      uri={product.productImage}
                      onCardPress={() => handleCardPress(product, category)}
                      onBasketPress={() => handleCardPress(product, category)}
                      onQuantityUp={() => onAddProductInBasket(basket, { ...product, quantitySelected: 1 })}
                      onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
                      quantity={quantity}
                      template={organisationTemplate}
                    />
                  );
                })}
              </ContentComponent>

            </div>
          )
        })}
        {!allCategories.length &&
          <Title
            label={"Aucun resultat disponible pour cette recherche!"}
            variant='h_xl'
            weight='bold'
            className={styles.noItemText}
            template={organisationTemplate}
          />
        }
      </div>
    );
  }, [
    navigateTo,
    isDesktop,
    sliceArrBy,
    allCategories,
    basket,
    organisationTemplate,
    handleCardPress,
    onSelectedCategory,
    onAddProductInBasket,
    onSelectedProductQuantityDown
  ]);

  return (
    <div>
      <Header
        searchValue={search}
        onSearch={setSearch}
        // onMarketPlaceIconPress={() => goTo('/')}
        organisationLogo={organisationLogo}
        onBasketIconPress={handleGoToBasket}
        basketItemsCount={basket.length}
        template={organisationTemplate}
      />
      <BannerSlider bannerSliderData={adaptedCarouselData} hideDetails />
      {renderContent()}
    </div>
  );
};
