import { useDispatch, useSelector } from "react-redux";

import { CLEAN_BASKET_REQUEST } from "../../modules";
import { useNavigation } from "@hooks";
import { enumNavigationPath } from "@enums";
import { basketType } from "../../types";
import { useCallback, useEffect } from "react";

export const useController = ()=> {

    const dispatch = useDispatch();
    const { navigateTo, goToNewTab } = useNavigation();

    const basket: basketType[] = useSelector(
        (state: any) => state.BasketReducers.basket
    );
    const handleOpenNewTab = useCallback(() => {
        const url = enumNavigationPath.bill;
        goToNewTab(url);
    }, [goToNewTab]);

    useEffect(() => {
        if (basket.length === 0) {
        navigateTo(enumNavigationPath.home);
        }
    }, [basket, navigateTo]);

    const onCleanBasket = useCallback(() => {
        return dispatch({
        type: CLEAN_BASKET_REQUEST,
        });
    }, [dispatch]);

    return {
        handleOpenNewTab,
        onCleanBasket,
    }
}
