import React, { useCallback, useMemo } from "react";
import { LogoContainerProps, LogoSizeType } from "./interface";
import './styles.css';
import { Image } from "@byme-ui-components/atomes";
import { LogoSizeEnum } from "@byme-ui-utilities/enums";

export const LogoContainer: React.FC<LogoContainerProps> = ({
    uri,
    size = LogoSizeEnum.regular,
    onLogoPress
}) => {

    const handleLogoPress = useCallback(() => {
        if (!onLogoPress) return;

        onLogoPress()
    }, [onLogoPress]);

    const getLogoSize = useMemo<LogoSizeType>(() => {
        switch (size) {
            case LogoSizeEnum.regular:

                return {
                    width: '50',
                    height: '50'
                }
            case LogoSizeEnum.medium:

                return {
                    width: '70',
                    height: '60'
                }
            case LogoSizeEnum.large:

                return {
                    width: '100',
                    height: '70'
                }


        }
    }, [size]);

    return (
        <div
            onClick={handleLogoPress}
        >
            <Image
                custumClassName="logo-container"
                source={uri}
                width={getLogoSize.width}
                height={getLogoSize.height}
            />
        </div>
    )
}