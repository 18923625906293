import { combineReducers } from "redux";

// Reducers
import SaleReducers from "./sales/reducers";
import BasketReducers from "./basket/reducers";
import CategoriesReducers from "./categories/reducers";
import ShopSessionReducers from "./shopSession/reducers";
import ProductReducers from "./products/reducers";
import PaymentTypeReducers from "./payment/reducers";
import LivraisonReducers from "./Livraison/reducers";
import OrganisationReducers from "./Organisations/reducers";
import SalesReducers from "./sales/reducers";
import BannersReducers from "./banners/reducers";
import LoginReducers from './Login/reducers';

export default combineReducers({
  SaleReducers,
  BasketReducers,
  CategoriesReducers,
  ShopSessionReducers,
  ProductReducers,
  PaymentTypeReducers,
  LivraisonReducers,
  OrganisationReducers,
  SalesReducers,
  BannersReducers,
  LoginReducers
});
