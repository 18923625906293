import { productActionsType, productInitialState } from "../../types";
import * as actions from "./actions";

const initialState: productInitialState = {
  allProducts: [],
  selectedProduct: null,
  getAllProductErrorState: false,
};

export default function ProductReducers(
  state: productInitialState = initialState,
  { type, payload }: productActionsType
) {
  switch (type) {
    case actions.GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
      };
    case actions.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: payload,
      };
    case actions.GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        getAllProductErrorState: payload,
      };
    case actions.SELECTED_PRODUCT_REQUEST:
      return {
        ...state,
      };
    case actions.SELECTED_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: payload,
      };
    case actions.SELECTED_PRODUCT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
