import { basketType } from "./basket.types"
import { OrganisationTypeEnum } from "./organisation.types"

export interface Sale {
    _organisationID: string
    _organisationType: OrganisationTypeEnum | string
    _sellerID?: string
    _id: string
    orderNumber: string
    basket: basketType[]
    totalToPay: number
    totalPay: number
    reduction: number
    paymentMethode: paymentMethode
    client?: string | clientDataType
    restaurantOrderStatus?: RestaurantOrderStatusEnum | null
    onlineOrderStatus?: OnlineOrderStatusEnum | null
    createAt: string
}

export interface clientDataType {
    firstname: string
    lastname: string
    phone: string
    country: string
    city: string
    street: string
    adress: string
}

export interface paymentMethode {
    methode: PaymentMethodeEnum
    mobilePayment?: MobilePaymentEnum | null
    credit?: creditState | null
}

export interface creditState {
    _saleID: string
    _clientID: string
    ammont: number
    isClose: boolean
}

export interface saleInitialState {
    addNewSaleSuccess: boolean
    addNewSaleFailure: boolean
    addNewTransactionErrorState: boolean;
    addNewTransactionSuccess: boolean;
}

export interface saleActionType {
    type: string
    payload: any
}

export enum PaymentMethodeEnum {
    mobilMoney = 'Paiement Mobile',
    esp = 'Espèces',
    cb = 'Carte Bancaire',
    cheque = 'Chèque',
    // credit = 'Crédit',
}

export enum MobilePaymentEnum {
    orangeMoney = 'Orange money',
    mtnMomo = 'MTN momo',
    moovMoney = 'Moov money',
    wave = 'Wave'
}

export enum RestaurantOrderStatusEnum {
    new = 'new',
    inProgress = 'in progress',
    ready = 'ready',
    inTable = 'in table'
}

export enum OnlineOrderStatusEnum {
    confirmed = 'Commande confirmée',
    beingProcessed = 'Préparation de la commande',
    courseDelivery = 'Commande expédiée',
    livery = 'Livré'
}
