export const GET_BASKET_REQUEST = "GET_BASKET_REQUEST";
export const GET_BASKET_SUCCESS = "GET_BASKET_SUCCESS";

export const ADD_PRODUCT_IN_BASKET_REQUEST = "ADD_PRODUCT_IN_BASKET_REQUEST";
export const ADD_PRODUCT_IN_BASKET_SUCCESS = "ADD_PRODUCT_IN_BASKET_SUCCESS";

export const DELETE_PRODUCT_IN_BASKET_REQUEST =
  "DELETE_PRODUCT_IN_BASKET_REQUEST";
export const DELETE_PRODUCT_IN_BASKET_SUCCESS =
  "DELETE_PRODUCT_IN_BASKET_SUCCESS";

export const CLEAN_BASKET_REQUEST = "CLEAN_BASKET_REQUEST";
export const CLEAN_BASKET_SUCCESS = "CLEAN_BASKET_SUCCESS";

export const SELECTED_PRODUCT_QUANTITY_UP_REQUEST =
  "SELECTED_PRODUCT_QUANTITY_UP_REQUEST";
export const SELECTED_PRODUCT_QUANTITY_UP_SUCCESS =
  "SELECTED_PRODUCT_QUANTITY_UP_SUCCESS";

export const SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST =
  "SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST";
export const SELECTED_PRODUCT_QUANTITY_DOWN_SUCCESS =
  "SELECTED_PRODUCT_QUANTITY_DOWN_SUCCESS";
