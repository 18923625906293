import { put, call, takeEvery } from "redux-saga/effects";
import { OrganisationTypeActionEnum } from "./actions";
import { OrganisationModel } from "../../database";

function* getOrgaisationData({ organisationName }: any): any {
  const { getOrganisationData } = OrganisationModel();

  const response = yield call(getOrganisationData, organisationName);

  if (response?.code === 200) {
    yield put({
      type: OrganisationTypeActionEnum.GET_ORGANISATION_DATA_SUCCESSS,
      payload: response.data,
    });
  } else {
    yield put({
      type: OrganisationTypeActionEnum.GET_ORGANISATION_DATA_FAILURE,
      payload: true,
    });
  }
}

export default function* organisationSaga() {
  yield takeEvery(
    OrganisationTypeActionEnum.GET_ORGANISATION_DATA_REQUEST,
    getOrgaisationData
  );
}
