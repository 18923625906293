import {
    put,
    call,
    takeEvery
} from 'redux-saga/effects';
import { shopSession } from '../../database';

import * as actions from './actions';

function* getShopSessionRequest({ payload }: any): any {
    const { getSessionData } = shopSession();
    const response = yield call(getSessionData, payload);
    if (response?.code === 200) {
        yield put({ type: actions.GET_SESSION_DATA_SUCCESS, payload: response.data });
    } else {
        yield put({ type: actions.GET_SESSION_DATA_FAILURE, payload: true });
    }
}

export default function* shopSessionReducersSaga() {
    yield takeEvery(actions.GET_SESSION_DATA_REQUEST, getShopSessionRequest);
}