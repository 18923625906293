export enum enumNavigationPath {
  onboarding = "/:id",
  home = "/acceuil",
  carte = "/carte",
  product = "/produit",
  basket = "/panier",
  livraison = "/livraison",
  payment = "/paiement",
  recap = "/recap",
  success = "/succes",
  orderTracking = "/suivis-de-commande",
  bill = "/facture",
  login = "/login"
}

export type NavigationPathInterface = {
  onboarding: string;
  home: string;
  carte: string;
  product: string;
  basket: string;
  livraison: string;
  payment: string;
  recap: string;
  success: string;
  orderTracking: string;
  bill: string;
  login: string
};
