import React from "react";
import { useController } from "./useController";
import { ProductComponent } from "./Product.component";

export const ProductPage: React.FC = () => {

  const {
    selectedProduct,
    selectedCategory,
    basket,
    onSelectedProductQuantityUp,
    onSelectedProductQuantityDown,
    onAddProductInBasket,
    onSelectedProduct,
  } = useController();

  return (
    <ProductComponent
      selectedProduct={selectedProduct}
      basket={basket}
      selectedCategory={selectedCategory}
      onAddProductInBasket={onAddProductInBasket}
      onSelectedProduct={onSelectedProduct}
      onSelectedProductQuantityUp={onSelectedProductQuantityUp}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
    />
  )
}
