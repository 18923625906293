import React from "react";
import './styles.css';
import { DividerProps } from "./interface";
import { Divider as PRDivider } from 'primereact/divider';
import { DividerDirectionEnum, DividerTypeEnum } from "@byme-ui-utilities/enums";

export const Divider: React.FC<DividerProps> = ({
    type = DividerTypeEnum.solid,
    direction = DividerDirectionEnum.horizontal,
    customClassName,
}) => {

    return (
        <PRDivider
            className={`divider ${customClassName}`}
            type={type}
            layout={direction}
        />
    );
}