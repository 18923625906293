import React from 'react';

import { isHoverEnabled } from './HoverState';

interface Props {
    children: any;
  }

export const Hoverable = ({ children }: Props) => {
  const [isHovered, setHovered] = React.useState(false);
  const [showHover, setShowHover] = React.useState(true);

  function handleMouseEnter() {
    if (isHoverEnabled() && !isHovered) {
      setHovered(true);
    }
  }

  function handleMouseLeave() {
    if (isHovered) {
      setHovered(false);
    }
  }

  function handleGrant() {
    setShowHover(false);
  }

  function handleRelease() {}

  const child =
    typeof children === 'function'
      ? children(showHover && isHovered)
      : children;

  return React.cloneElement(React.Children.only(child), {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,

    // prevent hover showing while responder
    onResponderGrant: () => setShowHover(false),
    onResponderRelease: () => setShowHover(true),

    // if child is Touchable
    onPressIn: handleGrant,
    onPressOut: handleRelease
  });
};
