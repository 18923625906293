import React, { useEffect, useState } from "react";

import {
    collection,
    db,
    onSnapshot,
    query,
    where,
} from '../../database';
import { CollectionDbEnum, OrganisationTypeEnum, Sale } from "../../types";
import { OrderTrackingComponent } from "./OrderTracking.component";

export const OrderTracking: React.FC = () => {
    const [sale, setSale] = useState<Sale | null>(null);
    const [orderNumber, setOrderNumber] = useState<string>('');

    useEffect(() => {
        if (orderNumber !== '') {
            const q = query(collection(db, CollectionDbEnum.Sales),
                where("_organisationType", "==", OrganisationTypeEnum.Shop),
                where("orderNumber", "==", orderNumber),
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setSale(doc.data() as Sale);
                });

            });
        }
    }, [orderNumber]);

    return (
        <OrderTrackingComponent
            sale={sale}
            onGetOrderNumber={setOrderNumber}
        />
    )
};