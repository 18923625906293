import React from "react";

import { useController } from "./useController";
import { useNavigation } from "@hooks";
import { CarteComponent } from "./CarteComponent";

export const Carte: React.FC = () => {

  const {
    selectedCategory,
    productByCategories,
    onSelectedCategory,
    onSelectedProduct,
    basket,
    onAddProductInBasket,
    onSelectedProductQuantityDown,
  } = useController();
  const { navigateTo } = useNavigation();
  return (
    <CarteComponent
      selectedCategory={selectedCategory}
      productByCategories={productByCategories}
      onSelectedCategory={onSelectedCategory}
      onSelectedProduct={onSelectedProduct}
      navigateTo={navigateTo}
      onAddProductInBasket={onAddProductInBasket}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
      basket={basket}
    />
  )
}
