import React, { useCallback, useMemo, useState } from "react";
import { PaymentProps } from "./interfaces";
import './styles.css'
import { useBasket, useNavigation, useSessionData } from "@hooks";

import { enumToStringArray, formatNumberToPrice, generateOrderNumber } from "@functions";
import {
  MobilePaymentEnum,
  OnlineOrderStatusEnum,
  PaymentMethodeEnum,
  Sale
} from "../../types";
import uuid from "react-uuid";
import { BasketFloatingSection, CardWrapper, MobileOperatorCard } from "@byme-ui-components/templates";
import { Header, PaymentTypeCard } from "@byme-ui-components/organismes";
import { Carousel } from "@byme-ui-components/wrappers";
import { Button } from "@byme-ui-components/molecules";
import { DividerDirectionEnum, IconNameEnum } from "@byme-ui-utilities/enums";
import { enumNavigationPath } from "@enums";
import { Divider } from "@byme-ui-components/atomes";

export const PaymentComponent: React.FC<PaymentProps> = ({
  onSelectedPayment,
  paymentTypeSave,
  onAddNewSale,
  basket,
  deliveryData
}) => {
  const { goBack, navigateTo } = useNavigation();
  const { sessionData, organisationTemplate } = useSessionData()
  const { totalBasket } = useBasket()
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<PaymentMethodeEnum>(paymentTypeSave?.type!);

  const [selectedmobilMoneyMode, setSelectedmobilMoneyMode] = useState<string | null>(null);
  const [isOperatorSelected, setSelectedOperator] = useState<Record<string, boolean>>({});

  const paymentTypeList = useMemo(() => {
    return enumToStringArray(PaymentMethodeEnum);
  }, []);

  const mobilMoneyTypeList = useMemo(() => {
    return enumToStringArray(MobilePaymentEnum);
  }, []);

  const handlePressButton = useCallback(() => {
    if (paymentTypeSave) {
      const date = new Date();
      const sale: Sale = {
        _organisationID: sessionData?._id!,
        _organisationType: sessionData?._organisationType!,
        _id: uuid(),
        orderNumber: generateOrderNumber(String(date)),
        basket: basket,
        totalToPay: totalBasket,
        totalPay: 0,
        reduction: 0,
        paymentMethode: {
          methode: paymentTypeSave.type,
          mobilePayment: paymentTypeSave.type === PaymentMethodeEnum.mobilMoney ? paymentTypeSave.isMobilPayment : null,
        },
        createAt: String(date),
        client: deliveryData,
        onlineOrderStatus: OnlineOrderStatusEnum.confirmed
      };

      onAddNewSale(sale);
    }
  }, [
    paymentTypeSave,
    basket,
    sessionData,
    totalBasket,
    deliveryData,
    onAddNewSale,
  ]);

  const handleSetPaymentMethode = useCallback(
    (paymentMethode: PaymentMethodeEnum) => {
      setSelectedPaymentMode(paymentMethode);
      setSelectedOperator({})
      if (paymentMethode !== PaymentMethodeEnum.mobilMoney) {
        onSelectedPayment({
          type: paymentMethode,
        });
      }
    },
    [onSelectedPayment]
  );

  const renderRecapCard = useCallback(() => {

    return <BasketFloatingSection
      cardStyle={`bm-floating-card-${organisationTemplate}`}
      itemsCount={basket.length}
      amount={formatNumberToPrice(totalBasket)}
      onButtonPress={handlePressButton}
      template={organisationTemplate}
      content={<div>
        <div className={'bm-floating-recap-content'}>
          <p>Sous total</p>
          <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
        </div>
        {/* <div className={'bm-floating-recap-content'}>
          <p>Frais de livraison estimée</p>
          <p>Offerts</p>
        </div> */}
      </div>}
    />
  }, [totalBasket, basket, organisationTemplate, handlePressButton])

  const handleCardOrTitlePress = useCallback((index: string) => {
    setSelectedOperator(prev => ({
      //  ...prev,
      [index]: !prev[index]
    }));
  }, []);

  const handleSetMobilPaymentMethode = useCallback(
    (paymentMobileType: MobilePaymentEnum) => {
      setSelectedmobilMoneyMode(paymentMobileType);
      onSelectedPayment({
        type: selectedPaymentMode,
        isMobilPayment: paymentMobileType
      });
    },
    [onSelectedPayment, selectedPaymentMode]
  );

  return (
    <div>
      <Header
        goBackButtonLabel="Paiement"
        onGoBack={goBack}
        onGohome={() => navigateTo(enumNavigationPath.home)}
        template={organisationTemplate}
      />
      <Divider
        customClassName={`bm-delivery-divider-${organisationTemplate}`}
        direction={DividerDirectionEnum.horizontal}
      />
      <div className={`bm-payments-type-container-${organisationTemplate}`}>
        {
          paymentTypeList.map((paymentType, i) => {

            return (
              <CardWrapper
                key={i}
                cardStyle={"paymentWrapper"}
                direction="column"
                template={organisationTemplate}
              >
                <PaymentTypeCard
                  title={paymentType}
                  isSelected={selectedPaymentMode === paymentType}
                  onSelectPaymentType={() => handleSetPaymentMethode(paymentType)}
                  template={organisationTemplate}
                />
                <div>

                  <Carousel>
                    {mobilMoneyTypeList.map((mobilPayment, j) => {

                      if (
                        selectedPaymentMode === PaymentMethodeEnum.mobilMoney &&
                        paymentType === PaymentMethodeEnum.mobilMoney
                      ) {
                        return (
                          <MobileOperatorCard
                            key={j}
                            operatorName={mobilPayment}
                            template={organisationTemplate}
                            isSelected={selectedmobilMoneyMode === mobilPayment}
                            onCardOrTitlePress={() => {
                              handleCardOrTitlePress(String(j))
                              handleSetMobilPaymentMethode(mobilPayment)
                            }}
                          />
                        )
                      }
                      return null;
                    })}
                  </Carousel>
                </div>
              </CardWrapper>
            )
          })
        }
        <Button
          label="Valider le paiement"
          buttonIconName={IconNameEnum.check}
          onButtonPress={handlePressButton}
          template={organisationTemplate}
          customClassname={`bm-payment-btn-${organisationTemplate}`}
        />
      </div>

      {renderRecapCard()}
    </div>
  );
};
