import React, { useCallback, useMemo, useState } from "react";

import styles from './styles.module.css';
import OrderTrackingImg from '../../assets/svgImages/orderTraking.svg';
import OrderStepper from '../../assets/svgImages/orderStepper.svg';
import { enumToStringArray } from "@functions";
import { OnlineOrderStatusEnum } from "../../types";
import { Stepper } from "./components";
import { useScreenSize, useSessionData } from "@hooks";
import { Input } from "@byme-ui-components/organismes";
import { OrderTrackingProps } from "./interfaces";
import { Button } from "@byme-ui-components/molecules";


export const OrderTrackingComponent: React.FC<OrderTrackingProps> = ({
    sale,
    onGetOrderNumber,
}) => {
    const { isDesktop } = useScreenSize()
    const { organisationTemplate } = useSessionData()
    const [orderNumber, setOrderNumber] = useState<string>('');

    const steps = useMemo(() => {
        return enumToStringArray(OnlineOrderStatusEnum);
    }, []);

    const activeStep = useMemo(() => {
        return steps.indexOf(sale?.onlineOrderStatus!);
    }, [steps, sale]);

    const stepDescription = useMemo(() => {
        switch (activeStep) {
            case 0:
                return 'Le détaillant a confirmé la commande.';
            case 1:
                return "Le détaillant prépare l'article pour l'expédition";
            case 2:
                return "L'article a été donné au transporteur pour livraison. ";
            case 3:
                return "Votre colis vous a été livré à l'adresse indiqué";
        }
    }, [activeStep]);

    const handlePressButton = useCallback(() => {
        if (orderNumber) {
            onGetOrderNumber(orderNumber);
        }
    }, [onGetOrderNumber, orderNumber]);

    const renderInputOrderNumberComponent = useCallback(() => {
        if (isDesktop) return;
        return (
            <div className={styles.subContainer}>
                <p
                    className={styles.titlePage}
                >
                    Pour suivre votre commande, entrez l’ID que vous avez reçu par mail.
                </p>

                <img
                    src={OrderTrackingImg}
                    alt="img"
                />

                <Input
                    type='text'
                    placeholder="Numero de commande"
                    inputValue={orderNumber}
                    onValueChange={setOrderNumber}
                    template={organisationTemplate}
                />
                <Button
                    label="Valider"
                    onButtonPress={handlePressButton}
                    template={organisationTemplate}
                />
            </div>
        )
    }, [handlePressButton, isDesktop, orderNumber, organisationTemplate]);

    const renderInputOrderNumberDesktopComponent = useCallback(() => {
        if (!isDesktop) return;
        return (
            <div className={styles.subContainer}>
                <div className={styles.desktopFirstRowchild}>
                    <p
                        className={styles.titlePage}
                    >
                        Pour suivre votre commande, entrez l’ID que vous avez reçu par mail.
                    </p>
                    <Input
                        placeholder="Numero de commande"
                        inputValue={orderNumber}
                        onValueChange={setOrderNumber}

                        type='text'
                        template={organisationTemplate}
                    />
                    <Button
                        label="Valider"
                        onButtonPress={handlePressButton}
                        template={organisationTemplate}
                    />
                </div>

                <img
                    src={OrderTrackingImg}
                    alt="img"
                    className={styles.desktopImage}
                />
            </div>
        )
    }, [handlePressButton, isDesktop, orderNumber, organisationTemplate]);

    const renderContent = useCallback(() => {

        return (
            <React.Fragment>
                {renderInputOrderNumberComponent()}
                {renderInputOrderNumberDesktopComponent()}
            </React.Fragment>
        )
    }, [renderInputOrderNumberComponent, renderInputOrderNumberDesktopComponent])

    const renderOrderSteper = useCallback(() => {
        return (
            <div className={styles.subContainer_2}>
                <img
                    src={OrderStepper}
                    alt="img"
                    className={styles.orderStepperImg}
                />

                <p className={styles.orderStepperTxt}>
                    Ou se trouve votre commande ?
                </p>

                <div className={styles.stepperContainer}>
                    <Stepper
                        activeStep={activeStep}
                    >
                        <h4 style={{ textAlign: 'center' }}
                        >{stepDescription}</h4>
                    </Stepper>
                </div>
            </div>
        )
    }, [stepDescription, activeStep]);

    return (
        <div className={styles.container}>
            {
                sale ?
                    renderOrderSteper()
                    : renderContent()
            }
        </div>
    )
}