import React, { useMemo } from "react";
import './styles.css';
import { MobileOperatorCardrProps } from "./interface";
import { CardWrapper } from "../CardWrapper";
import { Paragraph } from "@byme-ui-components/atomes";

export const MobileOperatorCard: React.FC<
    MobileOperatorCardrProps
> = ({
    operatorName,
    imageSource,
    isSelected,
    template,
    onCardOrTitlePress
}) => {
        const card_wrapper_style = useMemo(() => {
            if (!isSelected) return 'bm-card-wrapper';
            return `bm-card-wrapper bm-card-wrapper-selected-${template}`;
        }, [
            isSelected,
            template
        ]);

        return (
            <div className="card-container"
            >
                <CardWrapper
                    onCardPress={onCardOrTitlePress}
                    cardStyle={card_wrapper_style}
                    template={template}
                >
                    <div className="bm-img-wrapper"
                    >
                        {imageSource && <img
                            className="bm-card-img"
                            src={imageSource}
                            alt="mobile-operator-card"
                        />}
                    </div>
                </CardWrapper>
                <Paragraph
                    onParagraphClik={onCardOrTitlePress}
                    label={operatorName}
                    weight='bold'
                    className="bm-card-title"
                    template={template}
                />
            </div >
        );
    } 