import { spawn } from "redux-saga/effects";

// Sagas
import saleSaga from "./sales/saga";
import basketSaga from "./basket/saga";
import categoriesSaga from "./categories/saga";
import shopSessionReducersSaga from "./shopSession/saga";
import productsSaga from "./products/saga";
import paymentSaga from "./payment/saga";
import livraisonSaga from "./Livraison/saga";
import organisationSaga from "./Organisations/saga";
import bannersSaga from "./banners/saga";
import loginSaga from "./Login/saga";

// Export the root saga
export default function* rootSaga() {
  yield spawn(saleSaga);
  yield spawn(basketSaga);
  yield spawn(categoriesSaga);
  yield spawn(shopSessionReducersSaga);
  yield spawn(productsSaga);
  yield spawn(paymentSaga);
  yield spawn(livraisonSaga);
  yield spawn(organisationSaga);
  yield spawn(bannersSaga);
  yield spawn(loginSaga)
}
