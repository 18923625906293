import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_SESSION_DATA_REQUEST } from "../modules";
import { useParams } from "react-router-dom";
import { Organisation } from "../types";

export const useSessionData = () => {
    let { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            onGetShopSession(id);
        }
    }, [id]);

    const sessionData: Organisation | null = useSelector(
        (state: any) => state.ShopSessionReducers.shopSession
    );
    const shopSessionErrorState = useSelector(
        (state: any) => state.ShopSessionReducers.shopSessionErrorState
    );
    const organisationTemplate = useMemo(() => sessionData?.template, [sessionData]);
    const organisationLogo = useMemo(() => {
        return sessionData?.logos?.logoMediumImage;
    }, [sessionData]);

    const onGetShopSession = useCallback(
        (token: string) => {
            dispatch({ type: GET_SESSION_DATA_REQUEST, payload: token });
        },
        [dispatch]
    );

    return {
        sessionData,
        organisationTemplate,
        shopSessionErrorState,
        organisationLogo
    };
};
