export enum IconNameEnum {
    menu = 'pi pi-equals',
    arrowLeft = 'pi pi-angle-left',
    arrowRightLong = 'pi pi-arrow-right',
    arrowLightLong = 'pi pi-arrow-left',
    close = 'pi pi-times',
    edit = 'pi pi-pen-to-square',
    trash = 'pi pi-trash',
    checkCircle = 'pi pi-check-circle',
    closeCircle = 'pi pi-times-circle',
    grid = 'pi pi-th-large',
    chevronLeft = 'pi pi-chevron-left',
    chevronRight = 'pi pi-chevron-right',
    plus = 'pi pi-plus',
    minus = 'pi pi-minus',
    basket = 'pi pi-shopping-cart',
    addToBasket = 'pi pi-cart-plus',
    shoppingBag = 'pi pi-shopping-bag',
    shop = 'pi pi-shop',
    circleExclamaton = 'pi pi-exclamation-circle',
    home = 'pi pi-home',
    check = 'pi pi-check',
    squareCheck = 'pi pi-check-square',
    download = 'pi pi-download',
    moneyBill = 'pi pi-money-bill'
}