import React from "react";

import { InputProps } from './interfaces';

import styles from './styles.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

export const Input: React.FC<InputProps> = ({
    placeholder,
    value,
    onChangeText,
    inputType = 'text',
    width,
    height,
    disabled,
}) => {

    return (
        <input
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={e => onChangeText(e.target.value)}
            type={inputType}
            className={styles.container}
            style={(width || height) ? { width, height } : {}}
        />
    )
}