import React, { ReactNode, useMemo } from "react";
import "./Stepper.css";

import mobile_stepper_1 from '../../../assets/svgImages/mobile_stepper_1.svg';
import mobile_stepper_2 from '../../../assets/svgImages/mobile_stepper_2.svg';
import mobile_stepper_3 from '../../../assets/svgImages/mobile_stepper_3.svg';
import mobile_stepper_4 from '../../../assets/svgImages/mobile_stepper_4.svg';

import desktop_stepper_1 from '../../../assets/svgImages/desktop_stepper_1.svg';
import desktop_stepper_2 from '../../../assets/svgImages/desktop_stepper_2.svg';
import desktop_stepper_3 from '../../../assets/svgImages/desktop_stepper_3.svg';
import desktop_stepper_4 from '../../../assets/svgImages/desktop_stepper_4.svg';


import { useScreenSize } from "@hooks";

type StepperProps = {
    activeStep: number
    children?: ReactNode;
}

export const Stepper: React.FC<StepperProps> = ({
    activeStep,
    children
}) => {
    const { isDesktop } = useScreenSize()
    const mobileStepper: { [key: number]: string } = useMemo(() => {
        const mobile_stepper = {
            0: mobile_stepper_1,
            1: mobile_stepper_2,
            2: mobile_stepper_3,
            3: mobile_stepper_4,
        }
        const desktop_stepper = {
            0: desktop_stepper_1,
            1: desktop_stepper_2,
            2: desktop_stepper_3,
            3: desktop_stepper_4,
        }

        return isDesktop ? desktop_stepper : mobile_stepper
    }, [isDesktop])

    return (
        <div className="stepper-container">
            <img src={mobileStepper[activeStep]} alt={`img_${activeStep}`} />
            {children}
        </div>
    );
};
