import { put, call, delay, takeEvery } from "redux-saga/effects";

import * as actions from "./actions";

import { Product } from "../../types";
import { ProductsModel } from "../../database";

function* getAllProductRequest({ payload }: any): any {
  const { getAllProducts } = ProductsModel();
  const response: { code: number; data: Product[] } = yield call(
    getAllProducts, payload
  );

  if (response.code === 200) {
    yield put({
      type: actions.GET_ALL_PRODUCTS_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({ type: actions.GET_ALL_PRODUCTS_FAILURE, payload: true });
  }
}

function* selectedProductRequest({ payload }: any): any {
  yield put({ type: actions.SELECTED_PRODUCT_SUCCESS, payload: payload });
}

export default function* productsSaga() {
  yield takeEvery(actions.GET_ALL_PRODUCTS_REQUEST, getAllProductRequest);
  yield takeEvery(actions.SELECTED_PRODUCT_REQUEST, selectedProductRequest);
}
