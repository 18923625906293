import { actionsPayment, paymentInitialState } from "../../types";
import * as actions from "./actions";


const initialState:paymentInitialState = {
    payment: null,
    paymentErrorState: false
}

export default function PaymentTypeReducers(
    state:paymentInitialState = initialState, 
    {type, payload}: actionsPayment
){
    switch(type) {
        case actions.SET_PAYMENT_TYPE_REQUEST:
            return {
                ...state,
            }
        case actions.SET_PAYMENT_TYPE_SUCCESS: 
            return {
                ...state,
                payment: payload
            }
        case actions.SET_PAYMENT_TYPE_FAILURE:
            return {
                ...state,
                paymentErrorState: payload,
            }
        default:
            return state
    }
}