import React, { useState } from "react";

import { BannerSliderProps } from './interfaces';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from './styles.desktop.module.css';
import { useBannerSliderController } from "./useBannerSliderController";

export const BannerSliderDesktop: React.FC<BannerSliderProps> = ({
    bannerSliderData,
    hideDetails
}) => {
    const { paggingComponent, sliderItem } = useBannerSliderController()
    const [itemCenterIndex, setItemCenterIndex] = useState<number>(0);

    const settings = {
        infinite: true,
        swipeToSlide: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        // autoplaySpeed: 2000,
        // cssEase: "linear",
        dotsClass: "slick-dots slick-thumb",
        afterChange: function (index: number) {
            setItemCenterIndex(index);
        },
        customPaging: (i: number) => paggingComponent(i, itemCenterIndex),
    };

    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {
                    bannerSliderData.map((item, i) => sliderItem({ item, i, hideDetails }))
                }
            </Slider>
        </div>
    )
}
