export enum CollectionDbEnum {
    ActivationCodes = 'activationCodes',
    Organisations = '_Organisations',
    Users = '_Users',
    Categories = '_Categories',
    Clients = '_Clients',
    Products = '_Products',
    Sales = '_Sales',
    Stocks = '_Stocks',
    Suppliers = '_Suppliers',
    Banners='_Banners'
}
