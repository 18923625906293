import React from "react";
import { useController } from "./useController";
import { SuccessComponent } from "./Success.component";

export const Success: React.FC = () => {
  const {
    handleOpenNewTab,
    onCleanBasket,
  } = useController();

  return (
    <SuccessComponent
      onDownloadPress={handleOpenNewTab}
      onCleanBasket={onCleanBasket}
    />
  );
};
