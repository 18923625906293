import { useDispatch, useSelector } from "react-redux";

import {
    getBasket,
    SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
    SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
} from "../../modules";
import { basketType } from "../../types";
import { useCallback, useEffect } from "react";
import { scrollEffects } from "../../utils/utils";

export const useController = () => {
    const dispatch = useDispatch();

    const scrollToTop = useCallback(() => {
        scrollEffects();
    }, []);

    useEffect(() => {
        scrollToTop()
    }, []);

    const basket = useSelector(getBasket);

    const onSelectedProductQuantityUp = useCallback((
        basket: basketType[],
        newItem: basketType
    ) => {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
            payload: { basket, newItem },
        });
    }, [dispatch]);

    const onSelectedProductQuantityDown = useCallback((
        basket: basketType[],
        item: basketType
    ) => {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
            payload: { basket, item },
        });
    }, [dispatch]);

    return {
        basket,
        onSelectedProductQuantityUp,
        onSelectedProductQuantityDown,
    }
}
