import React, { useEffect } from "react";
import { BillProps } from "./interface";
import styles from "./styles.module.css";
import {
  formatNumberToPrice,
  formatedDate,
  generateOrderNumber,
  pdfGenerator,
} from "../../functions";

export const Bill: React.FC<BillProps> = ({ sale, logo }) => {
  const currency = "Fcfa";
  let extraPrice = 0;

  useEffect(() => {
    if (sale) {
      pdfGenerator("pdf-contain");
    }
  }, [sale]);
  const orderNumber = sale ? generateOrderNumber(sale?.createAt!) : "";
  return (
    <div id="pdf-contain">
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.headerText}>Facture</p>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.firstRow}>
            <div className={styles.logoBox}>
              <img src={logo} alt="logo" className={styles.logo} />
            </div>
            <div className={styles.billInfo}>
              <div className={styles.infoColumn}>
                <label>Facture N°</label>
                <label>Date</label>
                <div className={styles.neutralLine} />
              </div>
              <div className={styles.infoSecondColumn}>
                <label>{orderNumber}</label>
                <label>{formatedDate()}</label>
                <div className={styles.line} />
              </div>
            </div>
          </div>
          {/* USeR Informatins */}
          {/* <div className={styles.userInfo}>
          <label className={styles.factureNameFor}>Facture Pour:</label>
          <label className={styles.customerName}>Will Smith</label>
          <label>Adresse</label>
          <label>Ville</label>
          <label>Code postale</label>
        </div> */}
          {/* Orders table */}
          <div className={styles.tableContainer}>
            <table className={styles.strippedTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>PRODUIT</th>
                  <th> PRIX</th>
                  <th>QTE.</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {sale &&
                  sale.basket?.map((item, index) => {
                    const price =
                      item.productSellingPrice[
                        item.productSellingPrice.length - 1
                      ].price;

                    return (
                      <tr key={String(index)}>
                        <td className={styles.listNumber}>{index + 1}</td>
                        <td>
                          <ol>
                            {item.productName}
                            {!!item.extraSideDish &&
                              item.extraSideDish.map((extra, j) => {
                                if (item.extraSideDish) {
                                  extraPrice = item.extraSideDish.reduce(
                                    (acc, ext) => acc + ext.price,
                                    0
                                  );
                                }
                                return (
                                  <li
                                    key={String(j)}
                                    className={styles.lisiItemtext}
                                  >
                                    {`${extra.name} x
                                    ${formatNumberToPrice(extra.price)}
                                    ${currency}`}
                                  </li>
                                );
                              })}
                          </ol>
                        </td>
                        <td>
                          {formatNumberToPrice(price)} {currency}
                        </td>
                        <td>{item.quantitySelected}</td>
                        <td>
                          {formatNumberToPrice(
                            price * item.quantitySelected + extraPrice
                          )}{" "}
                          {currency}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {/* Bill Informations Row */}
          <div className={styles.billInformationsRow}>
            <div className={styles.billInformationText}>
              Merci pour votre achat
            </div>
            <div className={styles.billInformationsRow}>
              <div className={styles.itemColumn}>
                <label>Sous total</label>
                {/* <label>Tax</label> */}
              </div>
              <div className={styles.itemColumnSecond}>
                <label>
                  {sale && formatNumberToPrice(sale.totalToPay)} {currency}
                </label>
                {/* <label>
                {100} {currency}
              </label> */}
              </div>
            </div>
          </div>

          <div className={styles.paymentInfoRow}>
            {/* <div className={styles.paymentColumn}>
            <label>Informations de paiement</label>
            <label>Infos</label>
          </div> */}
            <div className={styles.paymenTotal}>
              <label>Total</label>
              <label className={styles.paymentTotalAmount}>
                {sale && formatNumberToPrice(sale.totalToPay)} {currency}
              </label>
            </div>
          </div>

          <div className={styles.lastRow}>
            {/* <div className={styles.lastRowColumn}>
            <label>07567889</label>
            <label>Cocody dérrière village</label>
            <label>mail@mail.com</label>
          </div> */}
            <div className={styles.lastRowColumn}>
              {/* <div className={styles.socialRow}>
              <label>hello</label>
              <label>hello</label>
              <label>hello</label>
            </div> */}
              <a href="https://bymentreprise.com/">www.bymentreprise.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
