import React, { useCallback } from "react";
import { SuccessProps } from "./interfaces";
import "./styles.css";
import { Icon, Paragraph, Title } from "@byme-ui-components/atomes";
import { IconNameEnum, SizeEnum } from "@byme-ui-utilities/enums";
import { useSessionData } from "@hooks";
import { Header } from "@byme-ui-components/organismes";

export const SuccessComponent: React.FC<SuccessProps> = ({
  onCleanBasket,
  onDownloadPress,
}) => {
  const { organisationTemplate } = useSessionData();

  const handleDownloadFacture = useCallback(() => {
    onDownloadPress();
  }, [onDownloadPress]);

  return (
    <div className={'container'}>
      <Header
        onGohome={onCleanBasket}
        template={organisationTemplate}
      />
      <Title
        label="Commande validée!"
        className={`bm-page-title-${organisationTemplate}`}
        variant="h_xl"
        weight="bold"
        template={organisationTemplate}
      />
      {/* the following components handles the imp component */}
      <div className={`bm-img-succes-container-${organisationTemplate}`} />

      <div className={`bm-content-wrapper-${organisationTemplate}`}>
        <div className={'grp_1'}>
          <Paragraph
            label="Merci pour votre commande"
            className={`bm-thanks-text-${organisationTemplate}`}
            weight="bold"
            variant='p_xl'
            template={organisationTemplate}
          />
          <Paragraph
            label="Vous recevrez un mail sur l’état d’avancement de votre commande et sa
          date de livraison"
            className={`bm-paragraph-text-${organisationTemplate}`}
            weight="regular"
            variant='p_xl'
            template={organisationTemplate}
          />
        </div>

        <div className="bm-bill-container">
          <div
            className="bill"
          />
          <div className={'bm-wondload-container'}>
            <Paragraph
              label=" Télécharger la facture"
              weight="bold"
              variant='p_md'
              template={organisationTemplate}
              onParagraphClik={handleDownloadFacture}
            />
            <Icon
              iconName={IconNameEnum.download}
              size={SizeEnum.xxmd}
              template={organisationTemplate}
              customStyle={{ marginLeft: 10 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
