import React, { useCallback, useMemo, useState } from "react";
import { QuantityUpDownButtonProps, iconSizeType } from "./interface";
import { Icon } from "../../atomes";
import { ColorsEnum, IconNameEnum, SizeEnum } from "@byme-ui-utilities/enums";
import './styles.css'
export const QuantityUpDownButton: React.FC<
    QuantityUpDownButtonProps
> = ({
    size = "btn_xs",
    template = "A",
    quantity = 0,
    iconColor = ColorsEnum.white,
    onAddPress,
    onRemovePress
}) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = useCallback(() => {
            setIsHovered(true);
        }, []);

        const handleMouseLeave = useCallback(() => {
            setIsHovered(false);
        }, []);

        const colorsForTemplates: Record<string, string> = useMemo(() => ({
            'A': iconColor,
            'B': isHovered ? ColorsEnum.white : ColorsEnum.orange_c400
        }), [iconColor, isHovered]);


        const cn_quantityWrapper: string = useMemo(() => (
            `bm-container-wrapper bm-container-wrapper-${template}`
        ), [template]);

        const cn_qunatyContainer: string = useMemo(() => (
            `bm-qty-up-down-container ${size}-${template} bm-qty-up-down-container-${template}`
        ), [template, size]);

        const iconsize: iconSizeType = useMemo(() => {
            switch (size) {
                case 'btn_xs':
                    return {
                        minWidth: '6em',
                        size: SizeEnum.xs
                    };
                case 'btn_sm':
                    return {
                        minWidth: '7em',
                        size: SizeEnum.s
                    };
                case 'btn_md':
                    return {
                        minWidth: '8em',
                        size: SizeEnum.xxmd
                    };
                case 'btn_xl':
                    return {
                        minWidth: '9em',
                        size: SizeEnum.xmd
                    };
            }
        }, [size]);

        const renderIcon = useCallback((name: 'plus' | 'minus') => (
            <Icon
                onIconPress={name === 'plus' ? onAddPress : onRemovePress}
                size={iconsize.size}
                iconName={IconNameEnum[name]}
                colors={colorsForTemplates[template]}
            />
        ), [iconsize.size, colorsForTemplates, template,onAddPress,onRemovePress])

        return (
            <div
                className={cn_quantityWrapper}
                style={{
                    minWidth: iconsize.minWidth,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    style={{ cursor: onRemovePress && 'pointer' }}
                    className={cn_qunatyContainer}
                    onClick={(event) => {
                        event.stopPropagation()
                        onRemovePress?.()
                    }}
                >
                    {renderIcon('minus')}
                </div>
                <p
                    className={`bm-qty-number bm-qty-number-${template}`}
                    style={{ margin: 0, fontSize: "18px" }}
                >
                    {quantity}
                </p>
                <div
                    style={{ cursor: onAddPress && 'pointer' }}
                    className={cn_qunatyContainer}
                    onClick={(event) => {
                        event.stopPropagation()
                        onAddPress?.()
                    }}
                >
                    {renderIcon('plus')}
                </div>
            </div>
        )
    }