export const formatNumberToPrice = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const limitText_15 = (text: string) => {
  if (text.length > 15) {
    return text.slice(0, 15) + "...";
  }
  return text;
};
