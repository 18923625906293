import React, { useCallback, useState } from "react";
import styles from './styles.module.css'
import { Input } from "@components";
import phone from '../../../../assets/svgImages/phone.svg';
import emailIcon from '../../../../assets/svgImages/email.svg';
import location from '../../../../assets/svgImages/location.svg';

import linkedIn from '../../../../assets/svgImages/linkedIn.svg';
import x from '../../../../assets/svgImages/x.svg';
import facebook from '../../../../assets/svgImages/facebook.svg';
import instagram from '../../../../assets/svgImages/instagram.svg';
import { useNavigation } from "@hooks";


export const MobileFooter: React.FC<any> = () => {
    const [email, setemail] = useState<string>('');
    const { goToNewTab } = useNavigation()
    const icons = [
        { link: '#', value: phone },
        { link: '#', value: emailIcon },
        { link: '#', value: location }
    ];
    const socialIcons = [
        { link: '#', value: linkedIn },
        { link: '#', value: x },
        { link: '#', value: facebook },
        { link: '#', value: instagram }
    ];

    const renderLinkContent = useCallback(({
        leftText,
        rightText,
        url,
        rightUrl
    }:
        {
            leftText: string
            rightText: string
            url: string
            rightUrl: string

        }) => {
        return (
            <div className={styles.menuRow}>
                <div onClick={() => window.open(url, '_blank')}>{leftText}</div>
                <div onClick={() => window.open(rightUrl, '_blank')}>{rightText}</div>
            </div>
        )
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.newsLetterStyle}>Inscrivez-vous à la newsletter</div>

            <div className={styles.inputStyle}>
                <Input
                    width={222}
                    placeholder="Email"
                    value={email}
                    onChangeText={setemail}
                />
            </div>
            <div className={styles.separator} />
            <div className={styles.contactIconRow}>
                {icons.map((icon, index) => {
                    return <img
                        onClick={() => goToNewTab(icon.link)}
                        key={String(index)}
                        className={styles.contactIcon}
                        src={icon.value}
                        alt={`contact_icon_${index}`}
                    />
                })}
            </div>
            <div className={styles.separator} />
            {renderLinkContent({
                leftText: 'A propos',
                rightText: 'CGU',
                url: 'https://bymentreprise.com/politique-de-confidentialite/',
                rightUrl: 'https://bymentreprise.com/politique-de-confidentialite/',
            })

            }
            {renderLinkContent({
                leftText: 'BymeShop',
                rightText: 'CGV',
                url: 'https://bymentreprise.com/politique-de-confidentialite/',
                rightUrl: 'https://bymentreprise.com/conditions-generales-de-ventes/',
            })}
            {renderLinkContent({
                leftText: 'BymeRestau',
                rightText: 'Rgpd',
                url: '#',
                rightUrl: '#',
            })}
            {renderLinkContent({
                leftText: 'www.bymentreprise.com',
                rightText: 'FAQ',
                url: 'https://bymentreprise.com/',
                rightUrl: 'https://bymentreprise.com/contactez-nous/',
            })}
            <div className={styles.separator} />

            <div className={styles.contactIconRow}>
                {socialIcons.map((icon, i) => (
                    <img
                        onClick={() => goToNewTab(icon.link)}
                        key={String(i)}
                        className={styles.contactIcon}
                        src={icon.value}
                        alt={`social_icon_${i}`}
                    />
                ))}
            </div>
            <div className={styles.separator} />
            <span className={styles.footer}>
                <span className={styles.entrepriseText}>by BYMENTREPRISE</span>
                <span className={styles.copyright}>{` | ${new Date().getFullYear()} © `}
                </span> <span className={styles.byme}>Byme</span></span>
        </div>
    )
}