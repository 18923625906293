import { CollectionDbEnum, Sale } from '../../types';
import {
    db,
    doc,
    setDoc,
} from '../config';

export const salesModel = ()=> {

    const newSale = async(sale: Sale)=> {
        console.log('====sale==>', sale);
        try {
            const docRef = doc(db, CollectionDbEnum.Sales, sale._id);
            await setDoc(docRef, sale);
            
            return {
                code: 200
            }
        } catch (error) {
            return {
                code: 500
            }
        }
    }

    return {
        newSale,
    }

}