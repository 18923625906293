import { actionBanners, bannersInitialState } from "../../types/banners.type";
import {
  GET_BANNERS_FAILURE,
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
} from "./actions";

const initialState: bannersInitialState = {
  banners: null,
  bannersSuccessState: false,
  bannersErrorState: false,
};

export default function BannersReducer(
  state: bannersInitialState = initialState,
  { type, payload }: actionBanners
) {
  switch (type) {
    case GET_BANNERS_REQUEST:
      return {
        ...state,
      };
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: payload,
        bannersSuccessState: true,
        bannersErrorState: false,
      };
    case GET_BANNERS_FAILURE:
      return {
        ...state,
        bannersSuccessState: false,
        bannersErrorState: payload,
      };
    default:
      return state;
  }
}
