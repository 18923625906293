import { useNavigate, useParams } from "react-router-dom";

export const useNavigation = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const navigateTo = (link: string) => {
    return navigate(`/${id}${link}`);
  };

  const goToNewTab = (link: string) => {
    window.open(`/${id}${link}`);
  };

  const goBack = ()=> {
    window.history.go(-1);
  }

  return {
    navigateTo,
    goToNewTab,
    navigate,
    goBack,
  };
};
