import React from "react";
import { useController } from "./useController";
import { RecapComponent } from "./Recap.component";

export const Recap: React.FC = () => {

  const {
    basket,
    deliveryData,
  } = useController();

  return (
    <RecapComponent
      basket={basket}
      deliveryData={deliveryData}
    />
  );
};
