import { useCallback, useState } from 'react';
import { auth, provider } from '../../database';
import { 
    ConfirmationResult, 
    RecaptchaVerifier, 
    signInWithPhoneNumber, 
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from 'firebase/auth';

const MAIL = 'hamed@mail.fr';
const PASSWORD = 'azerty';

export const useController = () => {

    const [userData, setUserData] = useState<any>({});
    
    const onGoogleLoginPress = useCallback(() => {
        signInWithPopup(auth, provider).then((data) => {
            setUserData(data.user)
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`Google Sign-In Error (${errorCode}): ${errorMessage}`);
        })
    }, []);

    const verifierWithRecaptcha = useCallback(() => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response: any) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // ...
                    console.log('RECAPTCHA_RESPONSE', response)
                    onLoginWithPhoneNumber()
                },
                'expired-callback': () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                }
            }, auth)
        }
    }, []);

    const onLoginWithPhoneNumber = useCallback(async () => {
        verifierWithRecaptcha()
        const appVerifier = window.recaptchaVerifier
        await signInWithPhoneNumber(auth, '+33 07 50 91 98 98', appVerifier)
        .then((confirmationResult: ConfirmationResult) => {
            let result = confirmationResult
            console.log('RECAPTCHA_RESULT', result)
            result.confirm(('989898')).then((user) => {
                console.log('USER RESULT', user)
                setUserData(user)
            }).catch((err) => {
                console.log('CONFIRME_ERROR', err)
            })
        }).catch((err: any) => {
            console.log('SMS_ERROR', err)
        })
    }, []);
    
    const onCreateAccount = useCallback(()=> {
        createUserWithEmailAndPassword(auth, MAIL, PASSWORD)
            .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;
                console.log('===user=>', user);
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }, []);

    const onSigninWithEmailAndPassword = useCallback(()=> {
        signInWithEmailAndPassword(auth, MAIL, PASSWORD)
            .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;
                console.log('===user=>', user);
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }, []);

    return {
        onGoogleLoginPress,
        verifierWithRecaptcha,
        userData,
        onLoginWithPhoneNumber,
        onCreateAccount,
        onSigninWithEmailAndPassword
    }
}
