import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import { navigationRoutes } from "./navigationRoutes";

const router = createBrowserRouter(navigationRoutes);

export const Navigations: React.FC<any> = () => {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};
