import React, { memo, useCallback, useMemo, useEffect } from "react";
import { HeaderProps } from "./interface";
import { Badge, LogoContainer } from "@byme-ui-components/molecules";
import { BadgeSizeEnum, IconNameEnum, LogoSizeEnum, SizeEnum } from "@byme-ui-utilities/enums";
import { Icon } from "@byme-ui-components/atomes";
import './styles.css';
import { Input } from "../Input";
import { useScreenSize } from "@hooks";

export const Header: React.FC<HeaderProps> = memo(({
    template = 'A',
    organisationLogo,
    basketItemsCount,
    searchValue,
    goBackButtonLabel,
    onSearch,
    onBasketIconPress,
    onLogoPress,
    onGoBack,
    onGohome,
    onMarketPlaceIconPress
}) => {
    const { isMobile } = useScreenSize();
    const iconSize = useMemo(() => SizeEnum[isMobile ? 'xmd' : 'md'], [isMobile]);

    const containerStyle = useMemo(() => {
        if (!!onGoBack && !!onGohome) {
            return "bm-header-back-button-both"
        }
        if (!!onGohome && !onGoBack) {
            return "bm-header-back-button-with-home"
        }

        if (!onGohome && !!onGoBack) {
            return "bm-header-back-button"
        }

    }, [onGohome, onGohome,onGoBack]);

    const renderSearcInput = useCallback(() => {
        if (!onSearch) return null;

        return (
            <div className="bm-input-icon-container">
                <Input
                    placeholder="Rechercher"
                    inputValue={searchValue || ''}
                    onValueChange={onSearch}
                    type="text"
                    template={template}
                />
            </div>
        );
    }, [onSearch, searchValue, template]);

    const handleScroll = useCallback(() => {
        const headerElement = document.getElementById("header");
        if (window.scrollY > 0) {
            headerElement?.classList.add("header-shadow");
        } else {
            headerElement?.classList.remove("header-shadow");
        }
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <header id="header" className="bm-header-container">
            {!!organisationLogo && (
                <div className="bm-header-logo-container">
                    <LogoContainer
                        onLogoPress={onLogoPress}
                        uri={organisationLogo}
                        size={LogoSizeEnum.large}
                    />
                </div>
            )}
            {(onGoBack || onGohome) && (
                <div className={containerStyle}>
                    {onGoBack && (
                        <div className="subRow">
                            <Icon
                                iconName={IconNameEnum.arrowLightLong}
                                size={iconSize}
                                onIconPress={onGoBack}
                                customStyle={{
                                    marginRight: SizeEnum[isMobile ? 's' : 'xmd'],
                                    cursor: 'pointer',
                                }}
                                template={template}
                            />
                            <p
                                className={`bm-header-go-back-label-${template}`}
                            >
                                {goBackButtonLabel}
                            </p>
                        </div>
                    )}
                    {!!onGohome && (
                        <Icon
                            iconName={IconNameEnum.home}
                            size={iconSize}
                            onIconPress={onGohome}
                            customStyle={{
                                marginRight: SizeEnum[isMobile ? 's' : 'xmd'],
                                cursor: 'pointer'
                            }}
                            template={template}
                        />
                    )}
                </div>
            )}
            <div className="bm-right-container">
                {renderSearcInput()}

                <div className="bm-header-basket-container">

                    {!!onMarketPlaceIconPress && (
                        <Icon
                            iconName={IconNameEnum.shop}
                            size={iconSize}
                            onIconPress={onMarketPlaceIconPress}
                            customStyle={{
                                marginRight: SizeEnum[isMobile ? 's' : 'xmd'],
                                cursor: 'pointer'
                            }}
                            template={template}
                        />
                    )}
                    {!!onBasketIconPress && (
                        <>
                            <Icon
                                iconName={IconNameEnum.basket}
                                size={iconSize}
                                onIconPress={onBasketIconPress}
                                customStyle={{
                                    cursor: 'pointer'
                                }}
                                template={template}
                            />
                            {!!basketItemsCount && <Badge
                                size={BadgeSizeEnum.normal}
                                value={basketItemsCount}
                            />
                            }
                        </>
                    )}
                </div>

            </div>
        </header>
    );
});
