import {
    put,
    call,
    delay,
    takeEvery
} from 'redux-saga/effects';
import { LoginEnum } from './actions';


function* loginRequest({ payload }: any): any {

}

export default function* loginSaga() {
    yield takeEvery(LoginEnum.LOGIN_REQUEST, loginRequest);
}