import React, { useCallback, useMemo } from "react";
import './styles.css';
import { BasketCardProps } from "./interface";
import { useScreenSize } from "@byme-ui-utilities/hooks";
import { Icon, Image, Paragraph } from "@byme-ui-components/atomes";
import { QuantityUpDownButton } from "@byme-ui-components/organismes";
import { IconNameEnum, SizeEnum } from "@byme-ui-utilities/enums";

export const BasketCard: React.FC<BasketCardProps> = ({
    uri,
    name = "...",
    amount,
    template = 'A',
    description = "...",
    currency,
    quantity,
    onQuantityDown,
    onQuantityUp,
    onDeleteProduct,
}) => {
    const { isDesktop, isMobile } = useScreenSize();


    const text_variant = useMemo(() => !isMobile ? "p_xl" : "p_md", [isMobile]);
    const btn_size = useMemo(() => !isMobile ? "btn_md" : "btn_sm", [isMobile]);

    const renderQtyUpDwn = useCallback(() => {
        return (<QuantityUpDownButton
            size={btn_size}
            onAddPress={onQuantityUp}
            onRemovePress={onQuantityDown}
            quantity={quantity}
            template={template}
        />)
    }, [btn_size, quantity, template, onQuantityUp, onQuantityDown])

    const renderCardInformationsSection = useMemo(() => (
        <div className={`bm-basket-card-header-wrapper-${template}`} >
            <Paragraph
                className={`bm-basket-item-group-text item-group-text-${template}-title`}
                label={name}
                variant={text_variant}
                weight="bold"
            />
            <Paragraph
                label={description}
                variant={text_variant}
                className={`bm-basket-item-group-text`}
            />
            <div className={`separator-${template}`} />

        </div>
    ), [name, description, text_variant, template]);

    return (
        <div className={`bm-card-container-${template}`}>

            <div className={`bm-card-img-wrapper-${template}`}>
                {!!uri && <Image
                    custumClassName={`bm-basket-card-img-${template}`}
                    width='200'
                    source={uri}
                />}
            </div>
            <div className={`bm-card-item-group-${template}`}>
                {renderCardInformationsSection}
                <div className={`bm-qty-up-down-${template}`}>
                    {renderQtyUpDwn()}
                </div>
            </div>
            <div
                onClick={onDeleteProduct}
                className={`trash-icon-${template} ${!isDesktop ? `trash-icon-mobile-${template}` : undefined}`}
            >
                <Icon
                    iconName={IconNameEnum.trash}
                    size={SizeEnum[isDesktop ? 'xmd' : 'xxmd']}
                    onIconPress={() => onDeleteProduct?.()}
                    template={template}
                />
            </div>
            <div className={`bm-basket-item-price-${template}`}>
                <p className={`bm-basket-item-group-text-${template} item-group-text-${template}-title`}>
                    {amount} {currency}
                </p>
            </div>
        </div>

    )
};

