import React from "react";
import './styles.css';
import { BIcon } from "./interface";

export const Icon: React.FC<BIcon> = ({
    iconName,
    size = 14,
    colors = '#4A4A4A',
    customStyle,
    onIconPress,
    template
}) => {
    return (
        <i
            onClick={(event) => {
                event.stopPropagation()
                onIconPress?.()
            }}
            className={`bm-icon-${template} ${String(iconName)}`}
            style={{
                fontSize: size,
                color: colors,
                cursor: 'inherit',
                ...customStyle
            }}
        />
    );
};
