import React, { useState, useCallback } from 'react';
import './styles.css';
import { TabbarProps } from './interface';
import { Icon } from '@byme-ui-components/atomes';
import { IconNameEnum, SizeEnum } from '@byme-ui-utilities/enums';

export const Tabbar: React.FC<
    TabbarProps
> = ({
    tabs,
    iconPosition,
    template = 'A',
    showIndicator,
    containerStyle,
    headerContainerStyle,
    contentContainerStyle,
    onTabPress
}) => {
        const [activeIndex, setActiveIndex] = useState(0);

        const handleTabClick = useCallback(
            (index: number) => {
                setActiveIndex(index);
                const activeTab = document.querySelector('.bm-tab.active');
                if (activeTab instanceof HTMLElement) {
                    activeTab.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
                onTabPress?.(index)
            }, [onTabPress]);

        const renderIcon = useCallback(
            (name: IconNameEnum) => (
                <Icon
                    size={SizeEnum.xxmd}
                    iconName={name}
                />
            ), []);

        return (
            <div
                className="bm-tabbar-container"
                style={containerStyle}
            >
                <div
                    className={`bm-tabbar ${iconPosition} bm-tabbar-${template} 
                    ${!showIndicator
                            ? 'no-indicator'
                            : ''}`}
                    style={headerContainerStyle}
                >
                    {tabs.map(
                        (tab, index) => (
                            <div
                                key={index}
                                className={`bm-tab ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                {iconPosition === 'left' && tab.headerIcon && renderIcon(tab.headerIcon)}
                                <span
                                    className="bm-tab-header"
                                >
                                    {tab.headerTxt}
                                </span>
                                {iconPosition === 'right' && tab.headerIcon && renderIcon(tab.headerIcon)}
                            </div>
                        )
                    )}
                </div>
                <div
                    className="bm-tab-content"
                    style={contentContainerStyle}
                >
                    {tabs[activeIndex]?.content}
                </div>
            </div>
        );
    };
