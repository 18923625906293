import React, { useMemo } from "react";
import './styles.css';
import { BTitle } from "./interface";

export const Title: React.FC<BTitle> = ({
    label,
    variant = 'h_md',
    weight = 'bold',
    className,
    template = 'A'
}) => {

    const fontSize = useMemo(() => {
        switch (variant) {
            case 'h_xl':
                return '2em';
            case 'h_md':
                return '1.5em';
            case 'h_sm':
                return '1.17em';
            case 'h_xs':
                return '1em';
            default:
                return '1.5em';
        }
    }, [variant]);

    const fontFamily = useMemo(() => {
        switch (weight) {
            case 'bold':
                return 'bm_title_bold';
            case 'regular':
                return 'bm_title_regular';
            case 'light':
                return 'bm_title_light';
            default:
                return 'bm_title_regular';
        }
    }, [weight]);

    return (
        <h3
            style={{
                fontSize: fontSize,
            }}
            className={`bm_title ${fontFamily} ${className} bm-title-${template}`}
        >
            {label}
        </h3>
    )
}