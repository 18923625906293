import { basketActionType, basketInitialState } from "../../types";
import * as actions from "./actions";

const initialState: basketInitialState = {
  basket: []
};

export default function BasketReducers(
  state: basketInitialState = initialState,
  { type, payload }: basketActionType
) {
  switch (type) {
    case actions.GET_BASKET_REQUEST:
      return {
        ...state,
        basket: state.basket,
      };
    case actions.ADD_PRODUCT_IN_BASKET_REQUEST:
      return {
        ...state,
      };
    case actions.ADD_PRODUCT_IN_BASKET_SUCCESS:
      return {
        ...state,
        basket: payload,
      };
    case actions.DELETE_PRODUCT_IN_BASKET_REQUEST:
      return {
        ...state,
      };
    case actions.DELETE_PRODUCT_IN_BASKET_SUCCESS:
      return {
        ...state,
        basket: payload,
      };
    case actions.SELECTED_PRODUCT_QUANTITY_UP_REQUEST:
      return {
        ...state,
      };
    case actions.SELECTED_PRODUCT_QUANTITY_UP_SUCCESS:
      return {
        ...state,
        basket: payload,
      };
    case actions.SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST:
      return {
        ...state,
      };
    case actions.SELECTED_PRODUCT_QUANTITY_DOWN_SUCCESS:
      return {
        ...state,
        basket: payload,
      };
    case actions.CLEAN_BASKET_REQUEST:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
