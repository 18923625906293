import React, { useMemo } from "react";
import { BannerSliderProps } from './interfaces';
import { useScreenSize } from "@byme-ui-utilities/hooks";
import { BannerSliderDesktop } from "./BannerSlider.desktop";
import { BannerSliderMobile } from "./BannerSlider.mobile";

export const BannerSlider: React.FC<BannerSliderProps> = ({ bannerSliderData, hideDetails }) => {
    const { isDesktop } = useScreenSize()
    const BmBannerSlider = useMemo(() => isDesktop ? BannerSliderDesktop : BannerSliderMobile, [isDesktop]);

    return <BmBannerSlider
        bannerSliderData={bannerSliderData}
        hideDetails={hideDetails}
    />
};
