import React from 'react';
import './App.css';

import {
  Navigations,
} from './navigations';
import {
  store,
  persistor,
} from './modules';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <PrimeReactProvider>
          <Navigations />
        </PrimeReactProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
