import React, { useCallback } from "react";
import { RecapProps } from "./interfaces";
import "./styles.css";
import { formatNumberToPrice, formatTextMaxChars_15 } from "@functions";
import { useBasket, useNavigation, useSessionData } from "@hooks";
import { enumNavigationPath } from "@enums";
import { Button } from "@byme-ui-components/molecules";
import { Header } from "@byme-ui-components/organismes";
import { BasketFloatingSection, CardWrapper } from "@byme-ui-components/templates";
import { Divider, Paragraph } from "@byme-ui-components/atomes";
import { DividerDirectionEnum, IconNameEnum } from "@byme-ui-utilities/enums";

export const RecapComponent: React.FC<RecapProps> = ({
  basket,
  deliveryData,
}) => {
  const {
    navigateTo,
    goBack,
  } = useNavigation();
  const { organisationTemplate } = useSessionData();
  const { totalBasket } = useBasket()

  const onButtonPress = useCallback(() => {
    navigateTo(enumNavigationPath.payment);
  }, [navigateTo]);

  const onGoHome = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const renderDetailsOrder = useCallback(() => {
    return (
      <div className={`bm-order-recap-container-${organisationTemplate}`}>
        <div className={`bm-order-details-left-container-${organisationTemplate}`}>
          <div className={`bm-order-details-title-${organisationTemplate}`}>
            <label>
              Produits
            </label>
          </div>

          <div>
            {
              basket.map((el, i) => {
                return (
                  <p
                    key={i}
                    className={`bm-order-details-item-${organisationTemplate}`}
                  >
                    {formatTextMaxChars_15(el.productName)} x{el.quantitySelected}
                  </p>
                )
              })
            }
          </div>
          <Paragraph
            className={`bm-order-delivery-total-label-${organisationTemplate}`}
            label=" Total à payer"
            weight='bold'
            variant='p_xl'
            template={organisationTemplate}
          />
        </div>

        <div className={`bm-order-details-right-container-${organisationTemplate}`}>
          <div className={`bm-order-details-title-${organisationTemplate}`}>
            <label>
              Prix
            </label>
          </div>

          <div>
            {
              basket.map((el, i) => {
                const price = el.productSellingPrice[el.productSellingPrice.length - 1].price;
                return (
                  <p
                    key={i}
                    className={`bm-order-details-item-${organisationTemplate}`}
                  >
                    {formatNumberToPrice(el.quantitySelected * price)} Fcfa
                  </p>
                )
              })
            }
          </div>
          <Paragraph
            className={`bm-order-delivery-total-value-${organisationTemplate}`}
            label={`${formatNumberToPrice(totalBasket)} Fcfa`}
            weight='bold'
            variant='p_xl'
            template={organisationTemplate}
          />
        </div>
      </div>
    )
  }, [basket, totalBasket, organisationTemplate]);

  const renderRecapCard = useCallback(() => {

    return <BasketFloatingSection
      cardStyle={`bm-floating-card-${organisationTemplate}`}
      itemsCount={basket.length}
      amount={formatNumberToPrice(totalBasket)}
      onButtonPress={onButtonPress}
      template={organisationTemplate}
      content={<div>
        <div className={'bm-floating-recap-content'}>
          <p>Sous total</p>
          <p>{formatNumberToPrice(totalBasket)} Fcfa</p>
        </div>
        {/* <div className={'bm-floating-recap-content'}>
          <p>Frais de livraison estimée</p>
          <p>Offerts</p>
        </div> */}
      </div>}
    />
  }, [totalBasket,basket, organisationTemplate, onButtonPress])

  return (
    <div>
      <Header
        goBackButtonLabel="Recapitulatif"
        onGoBack={goBack}
        onGohome={onGoHome}
        template={organisationTemplate}
      />
      <Divider
        customClassName={`bm-delivery-divider-${organisationTemplate}`}
        direction={DividerDirectionEnum.horizontal} />
      {/* <div className={
        isDesktop
          ? `bm-order-refs-dsc-${organisationTemplate}`
          : `bm-order-refs-${organisationTemplate}`
      }>
        <p>Référence: {`WQR59A6`}</p> 
        <p>Date de livraison: {formatedDate()}</p>
      </div> */}
      <div className={`container-${organisationTemplate}`}>
        <div className={`bm-img-wrapper-${organisationTemplate}`}>
          <div className={`bm-order-delivery-image-${organisationTemplate}`} />
        </div>

        <CardWrapper
          noShadow
          cardStyle={`bm-wrapperStyle-${organisationTemplate}`}
          template={organisationTemplate}
        >
          {renderDetailsOrder()}
          <div className={`bm-order-delivery-details-container-${organisationTemplate}`}>
            <Paragraph
              className={`bm-order-delivery-details-title-${organisationTemplate}`}
              label="Informations de livraison"
              weight='bold'
              variant='p_xl'
              template={organisationTemplate}
            />
            <Paragraph
              className={`bm-order-delivery-details-text-${organisationTemplate}`}
              label={`${deliveryData.firstname} ${deliveryData.lastname}`}
              weight='bold'
              variant='p_md'
              template={organisationTemplate}
            />
            <Paragraph
              className={`bm-order-delivery-details-text-${organisationTemplate}`}
              label={deliveryData.phone}
              weight='bold'
              variant='p_md'
              template={organisationTemplate}
            />
            <Paragraph
              className={`bm-order-delivery-details-text-${organisationTemplate}`}
              label={`${deliveryData.street}, ${deliveryData.adress}, ${deliveryData.city}, ${deliveryData.country}`}
              weight='bold'
              variant='p_md'
              template={organisationTemplate}
            />
            <div className={`bm-order-delivery-btn-container-${organisationTemplate}`}>
              <Button
                label="Valider ma commande"
                buttonIconName={IconNameEnum.squareCheck}
                onButtonPress={onButtonPress}
                template={organisationTemplate}
              />
            </div>
          </div>
        </CardWrapper>
        {renderRecapCard()}
      </div>
    </div>

  );
};
