import React, { useCallback, useEffect, useState } from "react";

import { OnboardingProps } from "./interfaces";

import styles from "./styles.module.css";

import { useParams } from "react-router-dom";
import { enumNavigationPath } from "@enums";
import { useNavigation, useScreenSize } from "@hooks";
import { Input } from "../../components";
import {
  MobileFooter,
  Footer,
} from "./components";
import { mockProductCard } from "@__mocks__";

import logo from '../../assets/svgImages/bymeLogo.svg';
import { BannerSlider, BrandActivitySelector, BrandActivitySlider, ProductsSlider, ShopsCardSlider } from "@byme-ui-components/templates";

const bannerSliderDta = [
  {
    title: 'Burgers',
    subTitle: 'Des infos importantes ici !',
    buttonName: 'Acheter',
    link: 'link',
    bgImgUri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Café',
    subTitle: 'Des infos importantes ici !',
    buttonName: 'Voir',
    link: 'link',
    bgImgUri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Jeans',
    subTitle: 'Des infos importantes ici !',
    buttonName: 'Profiter',
    link: 'link',
    bgImgUri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Baskets',
    subTitle: 'Des infos importantes ici !',
    buttonName: 'Acheter',
    link: 'link',
    bgImgUri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
];

const productsSliderData = [
  {
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '0'
  },
  {
    uri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '1'
  },
  {
    uri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '2'
  },
  {
    uri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '3'
  },
  {
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '0'
  },
  {
    uri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '1'
  },
  {
    uri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '2'
  },
  {
    uri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '3'
  },
  {
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '0'
  },
  {
    uri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '1'
  },
  {
    uri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '2'
  },
  {
    uri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '3'
  },
];

const brandActivityData = [
  {
    name: 'Restaurant',
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '0'
  },
  {
    name: 'Cafe',
    uri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '1'
  },
  {
    name: 'Pizza',
    uri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '2'
  },
  {
    name: 'Coffret',
    uri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '3'
  },
  {
    name: 'Chaussures',
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '4'
  },
  {
    name: 'Restaurant',
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '5'
  },
  {
    name: 'Cafe',
    uri: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff?auto=format&fit=crop&q=80&w=2969&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '6'
  },
  {
    name: 'Pizza',
    uri: 'https://images.unsplash.com/photo-1605518216938-7c31b7b14ad0?auto=format&fit=crop&q=80&w=3109&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '7'
  },
  {
    name: 'Coffret',
    uri: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '8'
  },
  {
    name: 'Chaussures',
    uri: 'https://images.unsplash.com/photo-1550547660-d9450f859349?auto=format&fit=crop&q=80&w=2097&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    id: '9'
  },
];

const shopsCardListData = [
  {
    shopName: 'Shop 1',
    backgroundImg: 'https://info.medadom.com/hs-fs/hubfs/Junk-food%201B.jpg?width=677&height=451&name=Junk-food%201B.jpg',
    products: mockProductCard,
    cardColor: '#CA0000',
  },
  {
    shopName: 'Shop 1',
    backgroundImg: 'https://info.medadom.com/hs-fs/hubfs/Junk-food%201B.jpg?width=677&height=451&name=Junk-food%201B.jpg',
    products: mockProductCard,
    cardColor: '#CA0000',
  },
  {
    shopName: 'Shop 1',
    backgroundImg: 'https://info.medadom.com/hs-fs/hubfs/Junk-food%201B.jpg?width=677&height=451&name=Junk-food%201B.jpg',
    products: mockProductCard,
    cardColor: '#CA0000',
  },
  {
    shopName: 'Shop 1',
    backgroundImg: 'https://info.medadom.com/hs-fs/hubfs/Junk-food%201B.jpg?width=677&height=451&name=Junk-food%201B.jpg',
    products: mockProductCard,
    cardColor: '#CA0000',
  }
];

export const OnboardingComponent: React.FC<OnboardingProps> = ({
  shopSession,
  shopSessionErrorState,
}) => {

  let { id } = useParams();
  const { navigateTo } = useNavigation();
  const { isMobile, isDesktop } = useScreenSize()
  const [search, setSearch] = useState<string>('');
  const [selectedActivityIndex, setSelectedActivityIndex] = useState<number>(0);

  const renderSearchInput = useCallback(() => {
    return (
      <div className={styles.inputStyle}>
        <Input
          width={250}
          placeholder="Recherche"
          value={search}
          onChangeText={setSearch}
        />
      </div>
    )
  }, [search]);

  const renderMobileFooter = useCallback(() => {
    if (!isMobile) return null;

    return <MobileFooter />;
  }, [isMobile]);

  const renderFooter = useCallback(() => {
    if (isMobile) return null;

    return <Footer />;
  }, [isMobile]);

  useEffect(() => {
    if (shopSession) {
      navigateTo(enumNavigationPath.home);
    }
  }, [id, navigateTo, shopSession]);

  return (
    <div className={styles.container}>

      <div
        className={styles.logoContainer}
      >
        <img
          src={logo}
          alt="logo"
          className={styles.logoStyle}
        />
      </div>
      <BannerSlider
        bannerSliderData={bannerSliderDta}
      />

      {!isDesktop && renderSearchInput()}

      <ProductsSlider
        products={productsSliderData}
      />

      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>
          Nos Partenaires
        </p>
        <p className={styles.sectionSubTitle}>
          *nos partenaires sont groupés par secteur d'activités
        </p>
      </div>

      {
        isDesktop && (
          <div className={styles.brandActivitySelectorPosition}>
            <p className={styles.brandActivitySelectorPositionTitle}>
              Sélectionnez un secteur d'activités :
            </p>

            <BrandActivitySelector
              activities={brandActivityData}
              selectedElement={brandActivityData[selectedActivityIndex]}
              onChangeActivitySelected={(index) => setSelectedActivityIndex(index)}
            />
          </div>
        )
      }

      {
        !isDesktop && (
          <p className={styles.avtivityGroupName}>
            Restaurants
          </p>
        )
      }
      <ShopsCardSlider
        shopCardList={shopsCardListData}
      />

      {
        !isDesktop && (
          <div className={styles.BrandActivitySliderPosition}>
            <BrandActivitySlider
              activities={brandActivityData}
            />
          </div>
        )
      }

      {renderMobileFooter()}

      {renderFooter()}
    </div>
  );
};
