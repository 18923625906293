import React, { useCallback } from "react";
import { ProductCardInterface } from "./interface";
import "./styles.css";
import { ColorsEnum, IconNameEnum, SizeEnum } from "@byme-ui-utilities/enums";
import { useScreenSize } from "@byme-ui-utilities/hooks";
import { Icon } from "@byme-ui-components/atomes";
import { CardWrapper } from "../CardWrapper";
import { QuantityUpDownButton } from "@byme-ui-components/organismes";

export const ProductCard: React.FC<ProductCardInterface> = (data) => {
    const { isMobile } = useScreenSize();
    const {
        uri,
        name,
        amount,
        currency,
        quantity = 0,
        template = "A",
        description,
        onCardPress,
        onQuantityDown,
        onQuantityUp,
        onBasketPress,
    } = data;

    const renderProductName = useCallback(() => {
        return (
            <>
                <p
                    className={`bm-product-item-name-${template} ${quantity && `bm-hide-product-name-${template}`}`}
                >
                    {name}
                </p>
                <p
                    className={`bm-product-item-descirption-${template}`}
                >
                    {description}
                </p>
            </>
        );
    }, [
        quantity,
        name,
        template,
        description
    ]);

    const renderBasketCard = useCallback(() => {
        if (!onBasketPress) return null;

        return (
            <div
                className={`bm-basket-container-${template}`}>
                <Icon
                    size={SizeEnum.md}
                    colors={ColorsEnum.red_c400}
                    iconName={IconNameEnum.circleExclamaton}
                    onIconPress={onBasketPress!}
                    template={template}
                />
            </div>
        );
    }, [onBasketPress, template])

    return (
        <CardWrapper cardStyle={`bm-product-card-container-${template}`}>

            <div
                onClick={onCardPress}
                className={`bm-product-card-image-wrapper-${template}`}>
                <div
                    style={{ backgroundImage: `url(${uri})`,cursor:'pointer' }}
                    className={`bm-product-img-container-${template}`}
                />
            </div>
            <div className={`bm-product-card-text-block-${template}`}
            >
                <div className="text-column">
                    {renderProductName()}
                    <p className={`bm-product-item-price-${template}`}
                    >
                        {amount} {currency}
                    </p>

                </div>
                <div className={`icon-column-${template}`}>
                    {renderBasketCard()}
                    {!quantity ? (
                        <div
                            className={`bm-button-action-plus-container-${template}`}
                            onClick={onQuantityUp!}
                        >
                            <Icon
                                size={15}
                                colors={ColorsEnum.white}
                                iconName={IconNameEnum.plus}
                                onIconPress={onQuantityUp!}
                                customStyle={{
                                    cursor:'pointer'
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className={`bm-button-action-container-${template}`}
                        >
                            <QuantityUpDownButton
                                size={isMobile ? "btn_sm" : "btn_md"}
                                quantity={quantity!}
                                onAddPress={onQuantityUp!}
                                onRemovePress={onQuantityDown!}
                                template={template}
                            />
                        </div>
                    )}
                </div>
            </div>
        </CardWrapper>
    );
};
