import React from "react";
import {OnboardingComponent} from './Onboarding.component';
import { useSessionData } from "@hooks";
import { useController } from "./useController";
  
export const Onboarding:React.FC = ({

})=> {

  const {
    sessionData, 
    shopSessionErrorState,
  } = useSessionData();

  const {
    
  } = useController();

  return (
    <OnboardingComponent
      shopSession={sessionData} 
      shopSessionErrorState={shopSessionErrorState}
    />
  )
}