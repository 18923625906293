export const replaceSpecialChars = (str: string) => {
  const newStr = str.replace(/[^\w-]+/gi, "-");
  return newStr.toLowerCase();
};

export const scrollEffects = (y?: number, behavior?: ScrollBehavior) => {
  window.scrollTo({
    top: y || 0,
    behavior: behavior || "smooth",
  })
};