export const formatTextMaxChars_10 = (text: string)=> {
  if (text.length > 10) {
    return text.slice(0, 10) + "...";
  }
  return text;
}

export const formatTextMaxChars_15 = (text: string)=> {
  if (text.length > 15) {
    return text.slice(0, 15) + "...";
  }
  return text;
}

export const formatTextMaxChars_27 = (text: string)=> {
  if (text.length > 27) {
    return text.slice(0, 27) + "...";
  }
  return text;
}

export const formatTextMaxChars_50 = (text: string)=> {
  if (text.length > 50) {
    return text.slice(0, 50) + "...";
  }
  return text;
}
