import { ProductCardProps } from "../screens/Onboarding/components/ProductCard/interfaces";

export const mockProductCard: ProductCardProps[] = [
    {
        name: "Café noir",
        productImg: "https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?cs=srgb&dl=pexels-ella-olsson-1640772.jpg&fm=jpg",
        price: 250000
    },
    {
        name: "Pinceau",
        productImg: "https://images.confortetvie.fr/Produits/528/2019123_WEB1.jpg",
        price: 153000
    },
    {
        name: "Pizza",
        productImg: "https://www.saporinostri.it/wp-content/uploads/2020/10/le-origini-della-pizza-napoletana-e1601990439881-1024x604.jpg",
        price: 12000
    }
];