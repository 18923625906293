import { useCallback, useMemo } from 'react';
import { useScreenSize } from '@hooks';
import desktopStyles from './styles.desktop.module.css';
import mobileStyles from './styles.module.css';
import { BannerDataType } from './interfaces';


export const useBannerSliderController = () => {

    const { isDesktop } = useScreenSize();

    const styles = useMemo(() => isDesktop ? desktopStyles : mobileStyles, [isDesktop])

    const paggingComponent = useCallback((i: number, itemCenterIndex: number) => {
        return (
            <div
                className={
                    i === itemCenterIndex
                        ? styles.activeCustomDot : styles.customDot
                }
            />
        );
    }, [styles])

    const sliderItem = useCallback(
        ({ item, i, hideDetails }: { item: BannerDataType, i: number, hideDetails?: boolean }) => {
            return (
                <div
                    key={i}
                >
                    <div
                        className={styles.customSlide}
                    >
                        <img
                            className={styles.imgStles}
                            src={item.bgImgUri}
                            alt={item.subTitle}
                        />
                        {!hideDetails && (
                            <div className={styles.detailsContainer}>
                                <p className={styles.itemTitle}>
                                    {item.title}
                                </p>
                                <p className={styles.itemSubTitle}>
                                    {item.subTitle}
                                </p>

                                <div className={styles.itemButton}>
                                    {item.buttonName}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }, [styles])

    return {
        paggingComponent,
        sliderItem
    };

}