import React from "react";
import { PaymentTypeCardProps } from "./interface";
import './styles.css';
import { Paragraph } from "@byme-ui-components/atomes";
import { Checkbox } from "@byme-ui-components/molecules";

export const PaymentTypeCard: React.FC<
    PaymentTypeCardProps
> = ({
    title = 'Titre',
    isSelected,
    template = 'A',
    description,
    onSelectPaymentType
}) => {

        return (
            <div className="payment-card-wrapper">
                <div className="bm-row">
                    <Paragraph
                        label={title}
                        variant='p_xl'
                        weight="bold"
                        className={`title-${template}`}
                    />
                    <Checkbox
                        isChecked={Boolean(isSelected)}
                        customClassName={`checkbox-${template} ${isSelected && `bm-checkbox-checked-${template}`} `}
                        onValueChange={onSelectPaymentType}
                    />
                </div>
                {!!description && (
                    <Paragraph
                        label="Veuillez vous diriger vers la caisse pour finaliser le paiement."
                        variant='p_sm'
                        className="bm-p"
                    />
                )}
            </div>
        );
    }