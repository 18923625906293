import {
    collection,
    db,
    getDocs,
    query,
    where,
} from '../config';
import { CollectionDbEnum, Organisation, OrganisationTypeEnum } from '../../types';

export const shopSession = ()=> {

    const getSessionData = async(organisationNameParsed: string)=> {
        try {
            let organisation:Organisation | null = null;
            const q = query(
                collection(db, CollectionDbEnum.Organisations),
                where("organisationNameParsed", "==", organisationNameParsed.toLowerCase()),
                where("_organisationType", "==", OrganisationTypeEnum.Shop)
            );

            const querySnapshot = await getDocs(q);

            for (const doc of querySnapshot.docs) {
                organisation = doc.data() as Organisation;
            }

            return {
                code: 200,
                data: organisation
            };
        } catch (error) {
            return {
                code: 500
            }
        }
    }

    return {
        getSessionData,
    }

}