import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './styles.module.css';
import { BannerSliderProps } from './interfaces';
import { useBannerSliderController } from "./useBannerSliderController";

export const BannerSliderMobile: React.FC<BannerSliderProps> = ({ bannerSliderData, hideDetails }) => {
    const [itemCenterIndex, setItemCenterIndex] = useState<number>(0);
    const sliderRef = useRef<Slider | null>(null); // Ref to access the slider instance
    const { paggingComponent, sliderItem } = useBannerSliderController()

    // useEffect(() => {
    //     const sliderElement = sliderRef.current;

    //     if (sliderElement) {
    //         // For example, add event listeners or manipulate the slider
    //     }

    //     return () => {
    //         if (sliderElement) {
    //             // Clean up event listeners or any direct manipulations
    //         }
    //     };
    // }, []);

    const settings = {
        infinite: true,
        swipeToSlide: true,
        variableWidth: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        dotsClass: "slick-dots slick-thumb",
        afterChange: (index: number) => {
            setItemCenterIndex(index);
        },
        customPaging: (i: number) => paggingComponent(i, itemCenterIndex),
    };

    return (
        <div className={styles.container}>
            <Slider ref={sliderRef} {...settings}>
                {
                    bannerSliderData.map((item, i) => sliderItem({ item, i, hideDetails }))
                }
            </Slider>
        </div>
    );
};
