import { Category } from "../../types"

export const onFilterCategories = (state: any, categorires: Category[]) => {
    let activeCategories = categorires.filter(
        (category: Category) => category.isActive
    );
    return {
        ...state,
        allCategories: activeCategories
    }
}