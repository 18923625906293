import { useDispatch, useSelector } from "react-redux";
import { SET_LIVRAISON_CLIENT_INFOS_REQUEST } from "../../modules";
import { clientDataStateType } from "../../types";
import { useCallback, useEffect } from "react";
import { useSessionData } from "@hooks";
import { scrollEffects } from "../../utils/utils";

export const useController = ()=> {

    const dispatch = useDispatch();
    const { sessionData } = useSessionData();

    const scrollToTop = useCallback(() => {
        scrollEffects();
    }, []);

    useEffect(() => {
        scrollToTop()
    }, []);

    const clientData = useSelector(
        (state: any) => state.LivraisonReducers.clientData
    );

    const onSetClientDataLivraison = useCallback(
        (clientData: clientDataStateType) => {
        return dispatch({
            type: SET_LIVRAISON_CLIENT_INFOS_REQUEST,
            payload: clientData,
        });
        },
        [dispatch]
    );

    return {
        clientData,
        sessionData,
        onSetClientDataLivraison
    }
}
