import React from "react";
import { BadgeProps } from "./interface";

import { Badge as PRBadge } from 'primereact/badge';
import { BadgeSizeEnum, SeverityEnum } from "@byme-ui-utilities/enums";

export const Badge: React.FC<BadgeProps> = ({
    value,
    size = BadgeSizeEnum.normal,
    severity = SeverityEnum.danger
}) => {

    return (
        <PRBadge
            value={value}
            size={size}
            severity={severity}
        />
    )
}