import React, { useCallback, useState } from "react";

import {BrandActivitySelectorProps} from './interfaces';

import styles from './styles.module.css';

export const BrandActivitySelector: React.FC<BrandActivitySelectorProps> = ({
    activities,
    selectedElement,
    onChangeActivitySelected
}) => {

    const [showList, setShowList] = useState<boolean>(false);

    const handleToggleShiwList = useCallback(()=> {
        setShowList(!showList);
    }, [showList]);

    const handleACtivitySelected = useCallback((index: number)=> {
        onChangeActivitySelected(index);
        handleToggleShiwList()
    }, [handleToggleShiwList, onChangeActivitySelected]);

    const renderShowList = useCallback(()=> {
        if(!showList) return;

        return (
            <div className={styles.activitiesListSelectContainer}>
                {
                    activities.map((activity, i)=> {
                        if(activity.id === selectedElement.id) return null;

                        return (
                            <div
                                key={i}
                                style={{
                                    backgroundImage: `url(${activity.uri})`
                                }}
                                className={styles.activityItemContainer}
                                onClick={()=> handleACtivitySelected(i)}
                            >
                                <div className={styles.activityItemNameContainer}>
                                    {activity.name}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }, [
        selectedElement,
        showList, 
        activities,
        handleACtivitySelected,
    ])
    
    return (
        <div className={styles.container}>
            <div
                style={{
                    backgroundImage: `url(${selectedElement.uri})`
                }}
                className={styles.selectedElementContainer}
                onClick={handleToggleShiwList}
            >
                <div className={styles.selectedNameContainer}>
                    {selectedElement.name}
                </div>
            </div>
            
            {renderShowList()}
        </div>
    )
}
