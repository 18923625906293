import * as actions from "../modules/shopSession/actions";

export interface Organisation {
  _id: string;
  _organisationType: OrganisationTypeEnum | string;
  organisationName: string;
  organisationNameParsed: string;
  template?: string;
  city: string;
  country: string;
  logos?: logosType;
  createAt: string;
}
export interface logosType {
  logoSmallImage: string;
  logoSmallImageRef: string;
  logoMediumImage: string;
  logoMediumImageRef: string;
  logoLargeImage: string;
  logoLargeImageRef: string;
}
export enum OrganisationTypeEnum {
  Restaurant = "Restaurant",
  Shop = "Shop",
}
export type OrganisationActionType = {
  type: string;
  payload: any;
};

export interface OrganisationInitialState {
  organisationData: Organisation | null;
  getOrganisationDataError: boolean;
}
export interface GetShopSessionRequestAction {
  type: typeof actions.GET_SESSION_DATA_REQUEST;
  payload: string;
}

export interface GetShopSessionSuccessAction {
  type: typeof actions.GET_SESSION_DATA_SUCCESS;
  payload: Organisation | null;
}

export interface GetShopSessionFailureAction {
  type: typeof actions.GET_SESSION_DATA_FAILURE;
  payload: boolean;
}

// Union de tous les types d'actions pour le saga
export type ShopSessionActions =
  | GetShopSessionRequestAction
  | GetShopSessionSuccessAction
  | GetShopSessionFailureAction;