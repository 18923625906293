import React, { useMemo } from "react";
import { InputProps } from "./interface";
import { InputText } from "primereact/inputtext";
import './styles.css';
import { useScreenSize } from "@byme-ui-utilities/hooks";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';

export const Input: React.FC<InputProps> = ({
    type = 'text',
    inputValue = '',
    placeholder = "Saisir votre text ici!",
    customClassName,
    customStyle,
    template = 'A',
    disabled,
    prefix,
    suffix,
    onValueChange
}) => {
    const { isMobile } = useScreenSize();

    const cn_textArea = useMemo(() => (
        `bm-input-area-container bm-input-area-container-${template} ${customClassName}`
    ), [template, customClassName]);

    const cn_input = useMemo(() => (
        `bm-input-container bm-input-container-${template} ${customClassName}`
    ), [template, customClassName]);

    const inputWidth = useMemo(() => {
        return isMobile ? '90%' : '45%'
    }, [isMobile]);


    if (type === 'textarea') {
        return (
            <InputTextarea
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => onValueChange?.(e.target.value)}
                autoResize
                rows={5}
                unstyled
                style={{
                    minWidth: inputWidth,
                    ...customStyle
                }}
                className={cn_textArea}
                disabled={disabled}
            />
        )
    }

    if (type === 'tel') {
        return (
            <InputMask
                id="phone"
                value={inputValue}
                unmask
                onChange={(e) => onValueChange?.(e?.target?.value!)}
                mask="(+225) 99 999 99 999"
                placeholder="(+225) 99 999 99 999"// dynamize contry code in the future.
                unstyled
                inputMode={type}
                style={{
                    minWidth: inputWidth,
                    ...customStyle
                }}
                className={cn_input}
            />
        )

    }
    if (type === 'numeric') {

        return (
            <InputNumber
                value={Number(inputValue)}
                onValueChange={(e) => onValueChange?.(String(e.target.value))}
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                unstyled
                // format
                inputMode={type}
                inputStyle={{
                    minWidth: inputWidth,
                    ...customStyle
                }}
                inputClassName={cn_input}
            />

        )
    }
    return (
        <InputText
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => { onValueChange?.(e.target.value) }}
            disabled={disabled}
            unstyled
            style={{
                minWidth: inputWidth,
                 ...customStyle
            }}
            className={cn_input}
        />
    )
}