import React from "react";
import './styles.css'
import { FloatingCardProps } from "./interface";
import { useScreenSize } from "@byme-ui-utilities/hooks";
import { CardWrapper } from "../CardWrapper";
import { Icon } from "@byme-ui-components/atomes";
import { ColorsEnum, IconNameEnum, SizeEnum } from "@byme-ui-utilities/enums";
import { Button } from "@byme-ui-components/molecules";

export const BasketFloatingSection: React.FC<FloatingCardProps> = React.memo(({
    title = "TOTAL",
    amount = 0,
    currency = "Fcfa",
    itemsCount = 0,
    buttonLabel = "Passer ma commande",
    template = "A",
    content,
    cardStyle,
    buttonIconName,
    onButtonPress,
    onIconPress
}) => {
    const { isDesktop } = useScreenSize();
    // if (itemsCount === 0) return null;
    return (
        <CardWrapper
            template={template}
            noShadow
            cardStyle={`bm-fixed-bottom-container-${template} ${cardStyle}`}
        >
            <div className={`bm-fixed-bottom-section-container-${template}`}>
                {!!onIconPress && <div className={`bm-add-product-icon-container-${template}`}>
                    <Icon
                        iconName={IconNameEnum.plus}
                        size={SizeEnum[isDesktop ? 'xmd' : 'xxmd']}
                        onIconPress={onIconPress!}
                        colors={ColorsEnum.red_c500}
                    />
                </div>}

                <div className={`bm-fixed-bottom-container-subheader-${template}`}>
                    {content}
                    <div className={`bm-total-container-${template}`}>
                        <p className={`bm-total-label-${template}`}>
                            {title} {!!itemsCount && `(${itemsCount} articles)`}
                        </p>

                        {!!amount && <p className={`bm-total-amount-${template}`}>
                            {amount} {currency}
                        </p>}
                    </div>
                    <div className="bm-floating-section-button-wrapper">
                        <Button
                            buttonIconName={buttonIconName}
                            customClassname={`bm-floating-section-button-${template}`}
                            label={buttonLabel}
                            template={template}
                            onButtonPress={onButtonPress!}
                        />
                    </div>
                </div>
            </div>
        </CardWrapper>
    );
})