import {
    put,
    call,
    delay,
    takeEvery
} from 'redux-saga/effects';

import * as actions from "./actions";

function* setClientDataRequest({ payload }: any): any {
    yield put({type: actions.SET_LIVRAISON_CLIENT_INFOS_SUCCESS, payload});
}

export default function* livraisonSaga() {
    yield takeEvery(actions.SET_LIVRAISON_CLIENT_INFOS_REQUEST, setClientDataRequest);
}
