import { put, takeEvery } from "redux-saga/effects";

import * as actions from "./actions";

function* addProductInBasketRequest({ payload }: any): any {
  let isNewProduct = false;
  let basketCopy = [...payload.basket];
  let newItem = payload.newItem;
  const existingItem = basketCopy.find((basketItem) => basketItem._id === newItem._id);

  if (existingItem) {
    existingItem.quantitySelected += 1;
  } else {
    isNewProduct = true;
    basketCopy.push(newItem);
  }

  if(isNewProduct){
    yield put({ type: actions.ADD_PRODUCT_IN_BASKET_SUCCESS, payload: basketCopy });
  } else {
    yield put({ type: actions.SELECTED_PRODUCT_QUANTITY_UP_SUCCESS, payload: basketCopy });
  }
}

function* deleteProductInBasketRequest({ payload }: any): any {
  let isDeleteAction = false;
  let basketCopy = [...payload.basket];
  let item = payload.item;
  const existingItem = basketCopy.find((basketItem) => basketItem._id === item._id);

  if (existingItem) {
    existingItem.quantitySelected -= 1;

    if (existingItem.quantitySelected === 0) {
      const itemIndex = basketCopy.indexOf(existingItem);
      basketCopy.splice(itemIndex, 1);
      isDeleteAction = true;
    }
  }

  if(isDeleteAction){
    yield put({ type: actions.DELETE_PRODUCT_IN_BASKET_SUCCESS, payload: basketCopy });
  } else {
    yield put({ type: actions.SELECTED_PRODUCT_QUANTITY_DOWN_SUCCESS, payload: basketCopy });
  }
}

export default function* basketSaga() {
  yield takeEvery(actions.ADD_PRODUCT_IN_BASKET_REQUEST, addProductInBasketRequest);
  yield takeEvery(actions.DELETE_PRODUCT_IN_BASKET_REQUEST, deleteProductInBasketRequest);
  yield takeEvery(actions.SELECTED_PRODUCT_QUANTITY_UP_REQUEST, addProductInBasketRequest);
  yield takeEvery(actions.SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST, deleteProductInBasketRequest);
}
