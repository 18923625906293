import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProductProps } from "./interfaces";
import "./styles.css";
import { useNavigation, useScreenSize, useSessionData } from "@hooks";
import { enumNavigationPath } from "@enums";
import { formatNumberToPrice, formatTextMaxChars_15, formatTextMaxChars_50 } from "@functions";
import { Product } from "../../types";
import { Header, QuantityUpDownButton } from "@byme-ui-components/organismes";
import { Carousel } from "@byme-ui-components/wrappers";
import { ProductCard } from "@byme-ui-components/templates";
import { Button } from "@byme-ui-components/molecules";
import { IconNameEnum } from "@byme-ui-utilities/enums";
import { Paragraph } from "@byme-ui-components/atomes";

export const ProductComponent: React.FC<ProductProps> = ({
  selectedProduct,
  basket,
  selectedCategory,
  onSelectedProductQuantityUp,
  onSelectedProductQuantityDown,
  onAddProductInBasket,
  onSelectedProduct,
}) => {

  const { navigateTo, goBack } = useNavigation();
  const { organisationTemplate } = useSessionData()
  const { isDesktop } = useScreenSize();
  const [contentHeight, setContentHeight] = useState('50%');
  const contentRef = useRef<HTMLDivElement>(null);
  const startY = useRef<number>(0);
  const initialHeight = useRef<number>(0);
  const [showContent, setShowContent] = useState(false);

  const elementExist = useMemo(() => {
    return basket.find((ele) => ele._id === selectedProduct._id)
  }, [basket, selectedProduct]);

  const price = useMemo(() => {
    const priceTmp = selectedProduct?.productSellingPrice![
      selectedProduct?.productSellingPrice?.length! - 1
    ].price;

    if (elementExist) {
      return priceTmp * elementExist.quantitySelected;
    }

    return priceTmp!
  }, [
    selectedProduct,
    elementExist,
    basket,
  ]);

  const onAddQuantityProduct = useCallback(() => {
    if (!elementExist) {
      onAddProductInBasket(
        basket,
        {
          ...selectedProduct,
          quantitySelected: 1,
        },
      );
    } else {
      onSelectedProductQuantityUp(basket, elementExist);
    }
  }, [
    basket,
    onAddProductInBasket,
    onSelectedProductQuantityUp,
    selectedProduct,
    elementExist
  ]);

  const onDeleteQuantityProduct = useCallback(() => {
    if (elementExist) {
      onSelectedProductQuantityDown(basket, elementExist);
    }
  }, [
    basket,
    onSelectedProductQuantityDown,
    elementExist
  ]);

  const handleCartPress = useCallback(() => {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const renderQuantityActionButtons = useCallback(() => {
    const quantity = elementExist && elementExist.quantitySelected;

    return (
      <div className="bm-quantity-up-down-wrapper">
        <QuantityUpDownButton
          quantity={quantity!}
          onAddPress={onAddQuantityProduct}
          onRemovePress={onDeleteQuantityProduct}
          size='btn_md'
          template={organisationTemplate}
        />
      </div>
    )
  }, [
    elementExist,
    organisationTemplate,
    onAddQuantityProduct,
    onDeleteQuantityProduct,
  ]);

  const renderButtonAddOrQuantityUpdateButton = useCallback(() => {
    return (
      <div className={"bm-action-btn-grp-container"}>
        {
          elementExist
            ? renderQuantityActionButtons()
            : (
              <Button
                label="Ajouter au panier"
                buttonIconName={IconNameEnum.addToBasket}
                onButtonPress={onAddQuantityProduct}
                template={organisationTemplate}

              />
            )
        }
      </div>
    )
  }, [elementExist, organisationTemplate, onAddQuantityProduct, renderQuantityActionButtons]);

  const renderSuggestionProducts = useCallback(() => {
    return (
      <div className={"bm-product-suggestion-container"}>
        <Paragraph
          label="A découvrir"
          className={`bm-product-suggestion-title-${organisationTemplate}`}
          variant='p_xl'
          weight="bold"
          template={organisationTemplate}
        />
        <Carousel withArrows>
          {selectedCategory?.produits?.map((product: Product, i: number) => {

            const productPrice = product.productSellingPrice[
              product.productSellingPrice.length - 1
            ].price;

            const elementEx = basket.find((ele) => ele._id === product._id);
            const quantity = elementEx && elementEx.quantitySelected;
            return (
              <div
                key={i}
                className={"bm-product-suggestion-item-container"}
              >
                <ProductCard
                  key={i}
                  template={organisationTemplate}
                  uri={product.productImage}
                  name={formatTextMaxChars_15(product.productName)}
                  description={formatTextMaxChars_50(product.productDescription)}
                  amount={formatNumberToPrice(productPrice)}
                  currency={'fcfa'}
                  onCardPress={() => {
                    onSelectedProduct(product);
                  }}
                  onBasketPress={() => {
                    onSelectedProduct(product);
                  }}
                  onQuantityUp={() => {
                    onAddProductInBasket(
                      basket,
                      {
                        ...product,
                        quantitySelected: 1,
                      },
                    );
                  }}
                  onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
                  quantity={quantity}
                />
              </div>
            )
          })}
        </Carousel>
      </div>
    )
  }, [
    basket,
    navigateTo,
    onSelectedProduct,
    selectedCategory,
    organisationTemplate,
    onAddProductInBasket,
    onSelectedProductQuantityDown,
  ]);

  const toggleContentHeight = useCallback(() => {
    const targetHeight = contentHeight === '50%' ? '80%' : '50%';
    setContentHeight(targetHeight);
  }, [contentHeight]);

  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    startY.current = event.touches[0].clientY;
    if (contentRef.current) {
      initialHeight.current = contentRef.current.clientHeight;
    }
  }, []);

  const handleTouchMove = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    const touch = event.touches[0];
    const distanceY = touch.clientY - startY.current;

    if (contentRef.current) {
      const newHeight = Math.max(Math.min(initialHeight.current - distanceY, window.innerHeight * 0.80), window.innerHeight * 0.50);
      setContentHeight(`${newHeight}px`);
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (contentRef.current) {
      const finalHeight = parseInt(contentHeight, 10);
      const targetHeight = finalHeight > window.innerHeight * 0.65 ? window.innerHeight * 0.80 : window.innerHeight * 0.50;
      setContentHeight(`${targetHeight}px`);
    }
  }, [contentHeight]);

  const renderProductDetails = useCallback(() => {

    return (
      <div className={`bm-details-sub-container-${organisationTemplate}`}>
        <Paragraph
          label={`${formatNumberToPrice(price)} fcfa`}
          className={`bm-product-price-${organisationTemplate}`}
          variant='p_xl'
          weight="bold"
          template={organisationTemplate}
        />
        <div>
          <Paragraph
            label={selectedProduct.productName}
            className={`bm-product-name-${organisationTemplate}`}
            variant='p_xl'
            weight="bold"
            template={organisationTemplate}
          />
          <Paragraph
            label={selectedProduct.productDescription}
            className={`bm-product-description-${organisationTemplate}`}
            variant='p_xl'
            weight="regular"
            template={organisationTemplate}
          />
        </div>

      </div>
    )
  }, [price, selectedProduct, organisationTemplate])

  const renderProductImage = useMemo(() => (
    <div
      className={`bm-product-bg-img-${organisationTemplate}`}
      style={{
        backgroundImage: `url(${selectedProduct.productImage})`,
      }}
      onClick={toggleContentHeight}
    >
      <div className="overlay" />
    </div>
  ), [selectedProduct, organisationTemplate, toggleContentHeight])

  const renderMobileAndTabletContent = useCallback(() => {
    if (isDesktop) return null;

    return (
      <>
        {renderProductImage}
        {showContent && (
          <div
            className={`bm-product-content-container-${organisationTemplate}`}
            ref={contentRef}
            style={{ height: contentHeight }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <>
              <div className={"bm-separator-container"} onClick={toggleContentHeight}>
                <div className={"bm-separator-element"} />
              </div>
              <div
                className={`bm-product-details-container-${organisationTemplate}`}
              >

                {renderProductDetails()}
              </div>
              {renderButtonAddOrQuantityUpdateButton()}
            </>
            {renderSuggestionProducts()}
          </div>
        )}
      </>
    )
  }, [
    showContent, contentHeight, selectedProduct, isDesktop,
    organisationTemplate, handleTouchStart, handleTouchMove,
    handleTouchEnd, renderProductDetails, renderButtonAddOrQuantityUpdateButton,
    renderSuggestionProducts, toggleContentHeight // include toggleContentHeight in dependencies
  ])

  const renderDesktopContent = useCallback(() => {
    if (!isDesktop) return null;

    return (
      <div >
        <div className={`bm-desktop-details-row-${organisationTemplate}`}>
          {renderProductImage}
          <div className={`bm-desktop-details-row-sub-container-${organisationTemplate}`}>
            {renderProductDetails()}
            {renderButtonAddOrQuantityUpdateButton()}
          </div>
        </div>
        {renderSuggestionProducts()}
      </div>
    )
  }, [
    isDesktop, renderProductImage, selectedProduct, organisationTemplate,
    renderProductDetails, renderSuggestionProducts, renderButtonAddOrQuantityUpdateButton
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header
        goBackButtonLabel="Produit"
        onGoBack={goBack}
        onGohome={() => navigateTo(enumNavigationPath.home)}
        onBasketIconPress={handleCartPress}
        basketItemsCount={basket?.length}
        template={organisationTemplate}
      />
      {renderMobileAndTabletContent()}
      {renderDesktopContent()}
    </>
  );
};
