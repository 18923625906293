import React from "react";

import {ProductsSliderProps} from './interfaces';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import styles from './styles.module.css';

const RenderNextArrow = (props: any)=> {
    const { onClick } = props;

    return (
        <div
            className={styles.nextArrowStyles}
            onClick={onClick}
        >
            {'>'}
        </div>
    );
}
  
const RenderPrevArrow = (props: any)=> {
    const { onClick } = props;

    return (
        <div
            className={styles.prevArrowStyles}
            onClick={onClick}
        >
            {'<'}
        </div>
    );
}

export const ProductsSlider: React.FC<ProductsSliderProps> = ({
    products
}) => {

    const settings = {
        infinite: true,
        slidesToShow: 2,
        swipeToSlide: true,
        variableWidth: true,
        speed: 300,
        nextArrow: <RenderNextArrow />,
        prevArrow: <RenderPrevArrow />
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <Slider {...settings}>
                    {
                        products.map((item, i)=> {
                            return (
                                <div
                                    key={i}
                                >
                                    <div
                                        className={styles.customSlide}
                                    >
                                        <img 
                                            src={item.uri}
                                            alt='article'
                                            className={styles.productItemStyles}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}
