import React, { useCallback } from "react";
import { CheckboxChangeEvent, Checkbox as PRCheckbox } from 'primereact/checkbox';
import { CheckboxProps } from "./interface";
import './styles.css'
import { Paragraph } from "@byme-ui-components/atomes";

export const Checkbox: React.FC<CheckboxProps> = ({
    isChecked,
    label,
    disabled = false,
    onValueChange,
    customClassName,
    labelCustomClassName,
}) => {

    const handleValueChange = useCallback(
        (e: CheckboxChangeEvent) => {
            onValueChange?.(Boolean(e.checked));
        }, [onValueChange]);

    // when adding customClassName add .p-checkbox-box in the css File too
    return (
        <div className="bm-checkbox-container-row">
            <PRCheckbox
                className={`p-checkbox-box ${customClassName}`}
                checked={isChecked}
                onChange={handleValueChange}
                disabled={disabled}
            />
            {!!label && (
                <Paragraph
                    label={label}
                    className={`bm-checkbox-label ${labelCustomClassName}`}
                />
            )}

        </div>
    )
}