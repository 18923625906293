import React, { useCallback } from "react";
import { BasketProps } from "./interfaces";
import styles from "./styles.module.css";
import { useBasket, useNavigation, useSessionData } from "@hooks";
import { enumNavigationPath } from "@enums";
import { formatNumberToPrice, formatTextMaxChars_15, formatTextMaxChars_50 } from "@functions";
import { BasketCard, BasketFloatingSection } from "@byme-ui-components/templates";
import { Header } from "@byme-ui-components/organismes";
import { IconNameEnum } from "@byme-ui-utilities/enums";

export const BasketComponent: React.FC<BasketProps> = ({
  basket,
  onSelectedProductQuantityDown,
  onSelectedProductQuantityUp,
}) => {
  const { navigateTo, goBack } = useNavigation();
  const { organisationTemplate } = useSessionData()
  const { totalBasket } = useBasket()

  const handleValidedOrder = useCallback(() => {
    navigateTo(enumNavigationPath.livraison);
  }, [navigateTo]);

  const handlePressGoToCatalogue = useCallback(() => {
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const renderFixedBottomSection = useCallback(() => {
    if (!basket?.length) return null
    return (
      <BasketFloatingSection
        buttonIconName={IconNameEnum.moneyBill}
        itemsCount={basket.length}
        amount={formatNumberToPrice(totalBasket)}
        onIconPress={handlePressGoToCatalogue}
        onButtonPress={handleValidedOrder}
        template={organisationTemplate}
      />
    )
  }, [
    handlePressGoToCatalogue,
    handleValidedOrder,
    totalBasket,
    basket,
    organisationTemplate
  ]);

  return (
    <div className={styles.container}>
      <Header
        goBackButtonLabel="Panier"
        onGoBack={handleGoBack}
        template={organisationTemplate}
      />

      <div className={styles.basketContainer}>
        {basket.map((el, i) => {
          const price =
            el.productSellingPrice?.[el.productSellingPrice.length - 1].price;
          const amount = formatNumberToPrice(price * el.quantitySelected)
          return (
            <BasketCard
              key={i}
              uri={el.productImage}
              name={formatTextMaxChars_15(el.productName)}
              description={formatTextMaxChars_50(el.productDescription)}
              amount={amount}
              currency="Fcfa"
              quantity={el.quantitySelected}
              onDeleteProduct={() => onSelectedProductQuantityDown(basket, el)}
              onQuantityDown={() => onSelectedProductQuantityDown(basket, el)}
              onQuantityUp={() => onSelectedProductQuantityUp(basket, el)}
              template={organisationTemplate}
            />
          );
        })}
        {basket.length === 0 && (
          <div className={styles.emptyBasketText}>
            <p>La panier est vide</p>
          </div>
        )}
      </div>

      {renderFixedBottomSection()}
    </div>
  );
};
