import {
    put,
    call,
    takeEvery
} from 'redux-saga/effects';

import { 
    SET_NEW_TRANSACTION_FAILURE, 
    SET_NEW_TRANSACTION_REQUEST,
    SET_NEW_TRANSACTION_SUCCESS,
} from './actions';
import { salesModel } from '../../database';

function* setNewSaleRequest({ payload }: any): any {
    const {newSale} = salesModel();
    const response = yield call(newSale, payload);
    if(response.code === 200){
        yield put({type: SET_NEW_TRANSACTION_SUCCESS, payload: true});
    } else {
        yield put({type: SET_NEW_TRANSACTION_FAILURE, payload: true});
    }
}

export default function* saleSaga() {
    yield takeEvery(SET_NEW_TRANSACTION_REQUEST, setNewSaleRequest);
}