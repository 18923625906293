import React, { useRef, useState, useEffect, useCallback } from 'react';
import './styles.css';
import { CarouselProps } from './interface';
import { useScreenSize } from '@byme-ui-utilities/hooks';
import { Hoverable, Icon } from '@byme-ui-components/atomes';
import { IconNameEnum } from '@byme-ui-utilities/enums';


export const Carousel = <ItemT,>({
  withBullets = false,
  bulletsSize = 10,
  withArrows = true,
  arrowsSize = 14,
  spacingBeforeFirstItem = 0,
  spacingAfterLastItem = 0,
  spacingBetweenItems = 35,
  containerStyle,
  listStyle,
  arrowStyle,
  bulletsContainerStyle,
  bulletStyle,
  data = [],
  children,
}: CarouselProps<ItemT>) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const { isDesktop } = useScreenSize();
  const [bulletIndex, setBulletIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });

  useEffect(() => {
    const handleScroll = () => {
      const carouselElement = carouselRef.current;
      if (!carouselElement) return;
      const scrollLeft = carouselElement.scrollLeft;
      const maxScroll = carouselElement.scrollWidth - carouselElement.clientWidth;
      const index = Math.round(scrollLeft / itemWidth);
      setBulletIndex(index);
      setShowArrows({ left: scrollLeft > 0, right: scrollLeft < maxScroll });
    };

    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [itemWidth, data.length, children]);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;

    const firstChild = carouselElement.firstChild as HTMLElement;
    if (!firstChild) return;

    const computedStyle = window.getComputedStyle(firstChild);
    const width = firstChild.offsetWidth;
    const marginRight = parseFloat(computedStyle.marginRight);
    const marginLeft = parseFloat(computedStyle.marginLeft);
    const totalWidth = width + marginRight + marginLeft;
    setItemWidth(totalWidth);
  }, [data.length, children]);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;

    const containerWidth = carouselElement.offsetWidth;
    const calculatedItemsPerPage = Math.floor(containerWidth / itemWidth);
    setItemsPerPage(calculatedItemsPerPage);
  }, [itemWidth, data.length, children]);

  const handleNavigation = useCallback(
    (increment: number) => {
      const carouselElement = carouselRef.current;
      if (!carouselElement) return;

      let newIndex = bulletIndex + increment;
      const totalItems = data.length || React.Children.count(children);
      newIndex = Math.max(0, Math.min(totalItems - 1, newIndex));
      const scrollLeft = newIndex * itemWidth;
      carouselElement.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      setBulletIndex(newIndex);
    },
    [bulletIndex, data.length, children, itemWidth]
  );

  const renderControls = useCallback(() => {
    const totalItems = data.length || React.Children.count(children);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="controls-container">
        {withBullets && (
          <div className="bullets-container" style={bulletsContainerStyle}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                className={`bullet ${index === bulletIndex && 'bullet-active'}`}
                style={{
                  ...bulletStyle,
                  width: bulletsSize,
                  height: bulletsSize,
                  marginRight: index === totalPages - 1 ? 0 : spacingBetweenItems,
                }}
                onClick={() => handleNavigation(index - bulletIndex)}
              />
            ))}
          </div>
        )}
        {isDesktop && withArrows && totalPages > 1 && (
          <div className="arrows-container">
            {showArrows.left && (
              <div
                className="arrow left-arrow"
                style={{ ...arrowStyle, width: arrowsSize, height: arrowsSize }}
              >
                <Icon
                  size={arrowsSize}
                  iconName={IconNameEnum.chevronLeft}
                  onIconPress={() => handleNavigation(-1)}
                />
              </div>
            )}
            {showArrows.right && (
              <div
                className="arrow right-arrow"
                style={{ ...arrowStyle, width: arrowsSize, height: arrowsSize }}
              >
                <Icon
                  size={arrowsSize}
                  iconName={IconNameEnum.chevronRight}
                  onIconPress={() => handleNavigation(1)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, [
    data, bulletIndex, bulletsSize, bulletsContainerStyle,
    bulletStyle, spacingBetweenItems, withBullets, withArrows,
    showArrows, arrowStyle, arrowsSize, children, handleNavigation,
    itemsPerPage, isDesktop,
  ]);

  return (
    <Hoverable>
      {(isHoverable: boolean) => (
        <div className="carousel-wrapper">
          <div
            ref={carouselRef}
            className="carousel-container"
            style={containerStyle}
          >
            <div
              className="carousel-content"
              style={{
                ...listStyle,
                paddingLeft: spacingBeforeFirstItem,
                paddingRight: spacingAfterLastItem,
              }}
            >
              {children}
            </div>
          </div>
          {isHoverable && renderControls()}
        </div>
      )}
    </Hoverable>
  );
};
