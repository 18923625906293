import React from "react";
import { useController } from "./useController";
import { BasketComponent } from "./Basket.component";

export const Basket: React.FC = () => {

  const {
    basket,
    onSelectedProductQuantityUp,
    onSelectedProductQuantityDown,
  } = useController();

  return (
    <BasketComponent
      basket={basket}
      onSelectedProductQuantityUp={onSelectedProductQuantityUp}
      onSelectedProductQuantityDown={onSelectedProductQuantityDown}
    />
  )
}
