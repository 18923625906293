export enum BadgeSizeEnum {
    normal = 'normal',
    large = 'large',
    xlarge = 'xlarge'
}

export enum IconSizeEnum {
    small = 'small',
    large = 'large',
}

export enum LogoSizeEnum {
    regular = "regular",
    medium = "medium",
    large = "large"
}

export enum SizeEnum {
    giant = 150,
    xxxbig= 130,
    xxbig= 120,
    xbig= 110,
    big = 100,
    xxh = 90,
    xh = 75,
    huge = 60,
    xxl = 50,
    xl = 40,
    l = 30,
    md = 25,
    xmd = 20,
    xxmd = 15,
    smd= 12,
    s = 10,
    ms= 8,
    xs = 5,
    xxs = 2,
}