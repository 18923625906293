import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CarteProps } from "./interfaces";
import styles from "./styles.module.css";
import { enumNavigationPath } from "@enums";
import { useNavigation, useSessionData } from "@hooks";
import { ProductCard } from "@byme-ui-components/templates";
import { TabbarTypes } from "src/types/tabbar.types";
import { Tabbar } from "@byme-ui-components/molecules";
import { Header } from "@byme-ui-components/organismes";
import { formatNumberToPrice, formatTextMaxChars_15, formatTextMaxChars_50 } from "@functions";
import { Product, productsByCategories } from "../../types";

export const CarteComponent: React.FC<CarteProps> = ({
  selectedCategory,
  productByCategories,
  onSelectedProduct,
  onSelectedCategory,
  onAddProductInBasket,
  onSelectedProductQuantityDown,
  basket,
}) => {
  const { navigateTo } = useNavigation();
  const navigate = useNavigation()
  const { organisationTemplate } = useSessionData();

  const [search, setSearch] = useState<string>('');
  const [categories, setCategories] = useState<productsByCategories | null>(null)
  const handleGoToBasket = useCallback(() => {
    navigateTo(enumNavigationPath.basket);
  }, [navigateTo]);

  const onGoBackPress = useCallback(() => {
    navigate.goBack()
  }, [navigate]);

  const handleCardPress = useCallback((product: Product) => {
    onSelectedProduct(product);
    navigateTo(enumNavigationPath.product);
  }, [onSelectedProduct])

  const renderCategoryImgContainer = useCallback(() => {
    return (
      <div
        className={styles.categoryContentContainer}
      >
        <div
          className={styles.categoryContainer}
          style={{
            backgroundImage: `url(${categories?.categoryImage})`,
          }}
        >
          <p className={styles.categoriesNameTxt}>
            {categories?.categoryName}
          </p>
          <div
            className={styles.categoryDarkScreen}
          />
        </div>
      </div>
    )
  }, [categories]);

  const renderTabContent = useCallback(() => {

    return (
      <div className={styles.productsContainer}>
        {categories?.produits.map((product, i) => {
          const price =
            product.productSellingPrice![
              product.productSellingPrice?.length! - 1
            ].price;
          const elementEx = basket.find((ele) => ele._id === product._id);
          const quantity = elementEx && elementEx.quantitySelected;

          return (

            <ProductCard
              key={i}
              name={formatTextMaxChars_15(product.productName)}
              description={formatTextMaxChars_50(product.productDescription)}
              amount={formatNumberToPrice(price)}
              currency={"Fcfa"}
              uri={product.productImage}
              onCardPress={() => handleCardPress(product)}
              onBasketPress={() => handleCardPress(product)}
              onQuantityUp={() => {
                onAddProductInBasket(
                  basket,
                  {
                    ...product,
                    quantitySelected: 1,
                  },
                );
              }}
              onQuantityDown={() => onSelectedProductQuantityDown(basket, elementEx!)}
              quantity={quantity}
              template={organisationTemplate}
            />
          );
        })}
      </div>
    )
  }, [
    basket, categories, organisationTemplate, handleCardPress,
    onAddProductInBasket, onSelectedProductQuantityDown
  ])

  const tabAdapter = useMemo<TabbarTypes[]>(() => {
    return productByCategories?.reduce<TabbarTypes[]>(
      (accumulator, currentValue) => {

        const tabItem: TabbarTypes = {
          headerTxt: currentValue.categoryName,
          content: renderTabContent(),
          category: currentValue
        };

        accumulator.push(tabItem);
        return accumulator;
      }, [] as TabbarTypes[]);

  }, [productByCategories, renderTabContent]);

  useEffect(() => {
    if (search && selectedCategory) {
      const filteredCateByPName = selectedCategory.produits.filter(
        (product) => product.productName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      if (filteredCateByPName.length > 0) {
        setCategories({
          ...selectedCategory,
          produits: filteredCateByPName,
        });
      } else {
        setCategories({
          ...selectedCategory,
          produits: [],
        });
      }
    } else {
      setCategories(selectedCategory);
    }
  }, [search, selectedCategory]);

  const renderSelectCategoryComponent = useCallback(() => {

    return (
      <Tabbar
        tabs={tabAdapter}
        onTabPress={(currentIndex) => {
          onSelectedCategory(tabAdapter[currentIndex!]?.category);
        }}
        template={organisationTemplate}
      />
    )
  }, [tabAdapter, organisationTemplate, onSelectedCategory])

  return (
    <div>
      <Header
        goBackButtonLabel="Carte"
        onGoBack={onGoBackPress}
        onBasketIconPress={handleGoToBasket}
        basketItemsCount={basket.length}
        template={organisationTemplate}
        searchValue={search}
        onSearch={setSearch}
      />

      {renderCategoryImgContainer()}
      {renderSelectCategoryComponent()}
    </div>
  );
};
