import React, { useCallback, useEffect, useState } from "react";
import { LivraisonProps } from "./interfaces";
import "./styles.css";
import { useNavigation, useScreenSize, useSessionData } from "@hooks";
import { enumNavigationPath } from "@enums";
import { Button } from "@byme-ui-components/molecules";
import { IconNameEnum } from "@byme-ui-utilities/enums";
import { Header, Input } from "@byme-ui-components/organismes";

export const LivraisonComponent: React.FC<LivraisonProps> = ({
  clientData,
  onSetClientDataLivraison,
}) => {
  const { navigateTo, goBack } = useNavigation();
  const { organisationTemplate } = useSessionData();
  const { isMobile } = useScreenSize()
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string>("Côte d'Ivoire");
  const [city, setCity] = useState<string>("Abidjan");
  const [street, setStreet] = useState<string>("");
  const [adress, setAdress] = useState<string>("");

  useEffect(() => {
    if (clientData) {
      setFirstname(clientData.firstname);
      setLastname(clientData.lastname);
      setPhone(clientData.phone);
      setCountry(clientData.country);
      setCity(clientData.city);
      setStreet(clientData.street);
      setAdress(clientData.adress);
    }
  }, [clientData]);

  const handleButtonPress = useCallback(() => {
    if (firstname && lastname && phone && street) {
      onSetClientDataLivraison({
        firstname,
        lastname,
        phone,
        country,
        city,
        street,
        adress,
      });
      navigateTo(enumNavigationPath.recap);
    } else {
      alert("* Tous les champs doivent etre remplis !");
    }
  }, [
    adress,
    city,
    country,
    firstname,
    lastname,
    navigateTo,
    onSetClientDataLivraison,
    phone,
    street
  ]);


  const renderInputContainer = useCallback(() => {
    const inputstyleCustomStyle = {
      height: 50,
      width: 470,
      ...(isMobile && { width: 300, })
    }
    return (
      <div className="bm-delivery-input-container">
        <Input
          placeholder="Prenom"
          inputValue={firstname}
          onValueChange={setFirstname}
          type="text"
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}

        />
        <Input
          placeholder="Nom"
          inputValue={lastname}
          onValueChange={setLastname}
          type="text"
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}

        />
        <Input
          placeholder="Téléphone"
          inputValue={phone}
          onValueChange={setPhone}
          type="tel"
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}

        />
        <Input
          placeholder="Pays"
          inputValue={country}
          onValueChange={setCountry}
          type="text"
          disabled
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}
        />
        <Input
          placeholder="Ville"
          inputValue={city}
          onValueChange={setCity}
          type="text"
          disabled
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}
        />
        <Input
          placeholder="Quartier"
          inputValue={street}
          onValueChange={setStreet}
          type="text"
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}
        />
        <Input
          placeholder="Adresse"
          inputValue={adress}
          onValueChange={setAdress}
          type='textarea'
          customStyle={inputstyleCustomStyle}
          template={organisationTemplate}
        />

        <Button
          buttonIconName={IconNameEnum.arrowRightLong}
          label="Suivant"
          onButtonPress={handleButtonPress}
          template={organisationTemplate}
        />
      </div>
    )
  }, [
    isMobile,
    adress,
    city,
    country,
    firstname,
    handleButtonPress,
    lastname,
    phone,
    street,
    organisationTemplate
  ]);

  return (
    <div>
      <Header
        goBackButtonLabel="Livraison"
        onGoBack={goBack}
        onGohome={() => navigateTo(enumNavigationPath.home)}
        template={organisationTemplate}
      />


      <div
        className={`bm-content-container-${organisationTemplate}`}
      >
        <div className="input-container-wrapper">
          {renderInputContainer()}
        </div>
        <div className="img-container-wrapper">
          <div className={`bm-delivery-bg-${organisationTemplate}`} />
        </div>
      </div>
    </div>
  );
};
