import { Category, CollectionDbEnum } from "../../types";
import {
  collection,
  db,
  getDocs,
  query,
  where,
} from "../config";

export const CategoriesModel = () => {

  const getAllCategories = async (organisationID: string) => {
    try {
      let categories: Category[] = [];
      const q = query(
        collection(db, CollectionDbEnum.Categories),
        where("_organisationID", "==", organisationID)
      );

      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        const category = doc.data() as Category;
        categories.push(category);
      }

      return {
        code: 200,
        data: categories,
      };
    } catch (error) {
      return {
        code: 500,
      };
    }
  };

  return {
    getAllCategories,
  };
};
