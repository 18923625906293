import React from "react";
import { PaymentMethodeEnum } from "../../types";
import { useController } from "./useController";
import { PaymentComponent } from "./Payment.component";

export const Payment: React.FC = () => {

  const {
    basket,
    deliveryData,
    paymentTypeSave,
    onSelectedPayment,
    onAddNewSale,
  } = useController();

  return (
    <PaymentComponent
      paymentTypeSave={paymentTypeSave ? paymentTypeSave : PaymentMethodeEnum.esp}
      onSelectedPayment={onSelectedPayment}
      onAddNewSale={onAddNewSale}
      basket={basket}
      deliveryData={deliveryData}
    />
  );
};
