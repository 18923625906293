export enum SeverityEnum {
    success = 'success',
    info = 'info',
    warning = 'warning',
    danger = 'danger',
}

export enum ButtonSeverityEnum {
    success = 'success',
    info = 'info',
    warning = 'warning',
    danger = 'danger',
    secondary = 'secondary',
    help = 'help'
}