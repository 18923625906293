import { enumNavigationPath } from "@enums";
import {
    NotFound,
    Home,
    Onboarding,
    Carte,
    Basket,
    Livraison,
    Payment,
    Success,
    ProductPage,
    Recap,
    OrderTracking,
    BillScreeen,
    Login,
} from "../screens";
import ProtectedRoute from "./ProtectedRoute";
export const navigationRoutes = [
    {
        path: "*",
        element: <NotFound />,
    },
    {
        path: "/",
        element: <Onboarding />,
    },
    {
        path: enumNavigationPath.onboarding,
        element: <Onboarding />,
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.home,
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.carte,
        element: (
            <ProtectedRoute>
                <Carte />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.product,
        element: (
            <ProtectedRoute>
                <ProductPage />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.basket,
        element: (
            <ProtectedRoute>
                <Basket />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.livraison,
        element: (
            <ProtectedRoute>
                <Livraison />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.payment,
        element: (
            <ProtectedRoute>
                <Payment />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.recap,
        element: (
            <ProtectedRoute>
                <Recap />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.success,
        element: (
            <ProtectedRoute>
                <Success />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.orderTracking,
        element: (
            <ProtectedRoute>
                <OrderTracking />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.bill,
        element: (
            <ProtectedRoute>
                <BillScreeen />
            </ProtectedRoute>
        ),
    },
    {
        path: enumNavigationPath.onboarding + enumNavigationPath.login,
        element: <Login />,
    },
]