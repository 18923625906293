export enum ColorsEnum {

    /* Basics */
    white = '#FFFFFF',
    black = '#000',
    black_night = '#0A0A0A',

    /* Purple */
    purple_c300 = '#7B2CBF',
    purle_c400 = '#752678',
    purple_c500 = '#170745',

    /* Grey */
    grey_c100 = '#FAFAFA',
    grey_c200 = '#E6E6E6',
    grey_c300 = '#C4C4C4',
    grey_c400 = '#AEB1C1',
    grey_c400_batleship = '#909090',
    grey_silver = '#CECBCB',
    grey_c500 = ' #7F7F7F',
    grey_c500_jet = '#2C2C2C',

    /* pink */
    pink_c300 = '#F49D9D',

    /* Red */
    red_c400 = '#EC5959',
    red_c500 = '#BC1616',
    /* Indigo */
    indigo_c500 = '#1A4777',
    /* Orange */
    orange_c300 = '#F6D18D',
    orange_c300_rgb = 'rgba(246, 209, 141, 0.4)',
    orange_c400 = ' #F2BD5B',
}