import { saleActionType, saleInitialState } from "../../types";
import {
  RESET_SALE_STATE,
  SET_NEW_TRANSACTION_FAILURE,
  SET_NEW_TRANSACTION_REQUEST,
  SET_NEW_TRANSACTION_SUCCESS,
} from "./actions";

const initialState: saleInitialState = {
  addNewSaleSuccess: false,
  addNewSaleFailure: false,
  addNewTransactionErrorState: false,
  addNewTransactionSuccess: false,
};

export default function SalesReducers(
  state: saleInitialState = initialState,
  { type, payload }: saleActionType
) {
  switch (type) {
    case RESET_SALE_STATE:
      return {
        ...initialState,
      };
    case SET_NEW_TRANSACTION_REQUEST:
      return {
        ...state,
      };
    case SET_NEW_TRANSACTION_SUCCESS:
      return {
        ...state,
        addNewTransactionErrorState: false,
        addNewTransactionSuccess: payload,
      };
    case SET_NEW_TRANSACTION_FAILURE:
      return {
        ...state,
        addNewTransactionErrorState: payload,
        addNewTransactionSuccess: false,
      };
    default:
      return state;
  }
}
