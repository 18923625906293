import React from "react";
import { ImageProps } from "./interface";
import './styles.css'
import { Image as PRImage } from 'primereact/image';

// to add custom style add "> img" to the css file of the parent in custumClassName
export const Image: React.FC<ImageProps> = ({
    source,
    width = "90",
    height,
    preview = false,
    custumClassName,
    onImagePress
}) => {

    return (
        <PRImage
            onClick={onImagePress}
            className={`image ${custumClassName}`}
            src={source}
            alt={`img-${source}`}
            width={width}
            height={height}
            preview={preview}
        />
    );
}