import { useDispatch, useSelector } from "react-redux";
import {
    ADD_PRODUCT_IN_BASKET_REQUEST,
    SELECTED_CATEGORY_REQUEST,
    SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
    SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
    SELECTED_PRODUCT_REQUEST,
} from "../../modules";
import { Product, basketType, productsByCategories } from "../../types";
import { useCallback, useEffect } from "react";
import { useSessionData } from "@hooks";
import { scrollEffects } from "../../utils/utils";

export const useController = () => {

    const dispatch = useDispatch();
    const { sessionData } = useSessionData();

    const scrollToTop = useCallback(() => {
        scrollEffects();
    }, []);

    useEffect(() => {
        scrollToTop()
    }, []);

    const selectedProduct = useSelector((state: any) => state.ProductReducers.selectedProduct);
    const selectedCategory = useSelector((state: any) => state.CategoriesReducers.selectedCategory);
    const basket = useSelector((state: any) => state.BasketReducers.basket);

    const onSelectedProductQuantityUp = useCallback((
        basket: basketType[],
        newItem: basketType
    ) => {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_UP_REQUEST,
            payload: { basket, newItem },
        });
    }, [dispatch]);

    const onSelectedProductQuantityDown = useCallback((
        basket: basketType[],
        item: basketType
    ) => {
        return dispatch({
            type: SELECTED_PRODUCT_QUANTITY_DOWN_REQUEST,
            payload: { basket, item },
        });
    }, [dispatch]);

    const onAddProductInBasket = useCallback((
        basket: basketType[],
        newItem: basketType,
    ) => {
        return dispatch({
            type: ADD_PRODUCT_IN_BASKET_REQUEST,
            payload: { basket, newItem },
        });
    }, [dispatch]);

    const onSelectedProduct = useCallback((product: Product) => {
        return dispatch({ type: SELECTED_PRODUCT_REQUEST, payload: product });
    }, [dispatch]);

    const onSelectedCategory = useCallback(
        (category: productsByCategories) => {
            return dispatch({ type: SELECTED_CATEGORY_REQUEST, payload: category });
        },
        [dispatch]
    );

    return {
        selectedProduct,
        selectedCategory,
        basket,
        sessionData,
        onSelectedProductQuantityUp,
        onSelectedProductQuantityDown,
        onAddProductInBasket,
        onSelectedProduct,
        onSelectedCategory
    }
}
