import React, { useEffect } from "react";
import { useController } from "./useController";
import { LoginComponent } from "./Login.component";

export const Login: React.FC = () => {

    const {
        onCreateAccount,
        onSigninWithEmailAndPassword,
    } = useController();

    useEffect(()=> {
        
    }, []);

    return (
        <LoginComponent />
    )
};
