import React, { PropsWithChildren, useMemo } from "react";
import './styles.css';
import { BMCardWrapperProps } from "./interface";

export const CardWrapper: React.FC<
    PropsWithChildren<BMCardWrapperProps>
> = ({
    children,
    cardStyle,
    direction = "row",
    noShadow,
    template="A",
    onCardPress
}) => {

        const bm_card_wrapper = useMemo(
            () => (
                `wrapper-container ${!noShadow && "wrapper-container-with-shadow"} wrapper-container-with-shadow-${template} ${cardStyle}`

            ), [noShadow, cardStyle, template]);

        return (
            <div
                onClick={onCardPress}
                className={`${direction === 'row'
                        ? 'wrapper-container-row'
                        : 'wrapper-container-column'
                    } ${bm_card_wrapper}`}
            >
                {children}
            </div >
        );
    } 