import { LoginInitialState, actionsLogin } from "../../types";
import { LoginEnum } from "./actions";

const initialState: LoginInitialState = {
    userData: null,
};

export default function LoginReducers(
    state: LoginInitialState = initialState,
    { type, payload }: actionsLogin
) {
    switch (type) {
        case LoginEnum.LOGIN_REQUEST:
            return {
                ...state,
            };
        case LoginEnum.LOGIN_SUCCES:
            return {
                ...state,
                clientData: payload,
            };
        case LoginEnum.LOGIN_FAILURE:
            return {
                ...state,
                error: payload
            };
        default:
            return state;
    }
}
